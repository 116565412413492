import React from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Document,
  Page,
  Text,
  Font,
  StyleSheet,
  pdf,
  View,
} from "@react-pdf/renderer";
import Loading from "../../Shared/Loading";

const UpcomingNotice = () => {
  const url = `https://brooklyn-mvc-pattern.vercel.app/classes/allClasses/Upcoming`;

  const { data: allClasses = [], isLoading } = useQuery({
    queryKey: ["Upcoming"],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });

  const createPDF = async () => {
    if (allClasses.length === 0) {
      console.log("Classes data is empty");
      return;
    }

    try {
      Font.register({
        family: "Source Sans Pro",
        src: "https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap",
      });

      const pdfContent = (
        <Document>
          <Page size="A4" style={styles.page}>
            <Text style={styles.heading}>
              Brooklyn Institute of Vocational Training, LLC
            </Text>
            <Text style={styles.address}>
              110-05 Liberty Avenue, Queens, New York 11419,
            </Text>
            <Text style={styles.contact}>
              Contact: +1929-386-0092, email:{" "}
              <Text style={styles.email}>info@mybrooklyninstitute.com</Text>{" "}
            </Text>
            <Text style={styles.subHeading}>Upcoming Classes</Text>
            <View style={styles.table}>
              {/* Table Header */}
              <View style={styles.tableRow}>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>Class Title</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>Start Date</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>End Date</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>Duration</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>Week Days</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>Timings</Text>
                </View>
              </View>
              {/* Dynamic Row Generation */}
              {allClasses.map((classItem, index) => (
                <View style={styles.tableRow} key={index}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{classItem.classTitle}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{classItem.startDate}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{classItem.endDate}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {classItem.description[0]}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {classItem.description[1]}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {classItem.openingHour}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </Page>
        </Document>
      );

      const blob = await pdf(pdfContent).toBlob();
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error creating PDF:", error);
    }
  };

  return (
    <div className="text-white">
      {isLoading ? (
        <Loading />
      ) : (
        <button
          className="px-3 py-2 rounded-md bg-primary font-semibold text-sm"
          onClick={createPDF}
        >
          View Notice
        </button>
      )}
    </div>
  );
};

// Updated styles to include table styling

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 40,
    paddingLeft: 10,
    paddingRight: 10,
  },
  heading: {
    fontSize: 20,
    marginBottom: 10,
    textAlign: "center",
    fontWeight: "extrabold",
  },
  subHeading: {
    fontSize: 16,
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 10,
    textDecoration: "underline",
  },
  address: {
    fontSize: 16,
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 5,
  },
  contact: {
    fontSize: 14,
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 10,
  },
  email: {
    fontSize: 14,
    color: "blue",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    width: "100%",
  },
  tableColHeader: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontWeight: "bold",
    fontSize: 15,
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    marginBottom: 5,
    fontSize: 10,
  },
});

export default UpcomingNotice;
