import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import "./JoinMail.css";

const JoinMail = () => {
  const date = Date.now();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const submitHandler = (data) => {
    const message = {
      date: date,
      email: data?.email,
    };

    fetch("https://brooklyn-mvc-pattern.vercel.app/mails", {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(message),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.acknowledged) {
          toast.success("Sign Up successfully");
          reset();
        }
      });
  };

  return (
    <div id="contact" className="mx-auto  roboto ">
      <div className=" bg-contact  ">
        <div className="h-full w-full bg-simple pt-10  pb-20  ">
          <form
            onSubmit={handleSubmit(submitHandler)}
            className="md:w-[85%] lg:w-[50%] w-11/12 mx-auto mt-3 block p-5 rounded "
          >
            <h1 className="text-3xl mt-10 form-top font-bold  text-white  text-center ">
              Join Our Mailing List
            </h1>
            <h1 className="font-me text-white  text-center mt-2">
              Be the first to hear about upcoming course registration and
              special events.
            </h1>

            <div className="flex justify-center items-center mt-5 gap-5">
              <div className="w-full">
                <input
                  type="email"
                  {...register("email", {
                    required: "Email is required",
                  })}
                  placeholder="Enter Your Email"
                  className=" w-full text-white  placeholder:text-white placeholder:font-semibold px-5 py-4   bg-simple  focus:outline-none rounded shadow-black border-[1px] border-white"
                />
                {errors.email && (
                  <p className="text-red-600">{errors.email.message}</p>
                )}
              </div>

              <button className="uppercase text-white bg-[#CA4757] px-8 py-5 w-36 rounded-md font-medium hover-effect md:text-base text-xs">
                Sign Up
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default JoinMail;
