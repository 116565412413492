import React, { useState, useEffect } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { IoIosImages } from "react-icons/io";
import OverlayLoading from "../../Shared/OverlayLoading";
import toast from "react-hot-toast";
import { useLoaderData } from "react-router-dom";
import { v4 as uuidv4 } from "uuid"; // Import UUID for unique IDs

const EditAlbum = () => {
  const [images, setImages] = useState([]);
  const [newImages, setNewImages] = useState([]); // New images to upload
  const [albumName, setAlbumName] = useState("");
  const [cardCover, setCardCover] = useState(null); // Card cover state
  const [coverImage, setCoverImage] = useState(null); // Cover image state
  const [loading, setLoading] = useState(false);
  const albumData = useLoaderData(); // Fetch album data

  useEffect(() => {
    if (albumData) {
      setImages(
        albumData.images.map((image) => ({ id: uuidv4(), url: image }))
      ); // Set unique IDs for existing images
      setAlbumName(albumData.albumName);
      setCardCover({ preview: albumData.cardCover });
      setCoverImage({ preview: albumData.coverImage });
    }
  }, [albumData]);

  const onDrop = (acceptedFiles) => {
    const newDroppedImages = acceptedFiles.map((file) => ({
      id: uuidv4(), // Assign a unique ID for each new file
      file: file,
      preview: URL.createObjectURL(file),
    }));
    setNewImages([...newImages, ...newDroppedImages]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleRemoveImage = (imageId, isNewImage = false) => {
    if (isNewImage) {
      setNewImages(newImages.filter((image) => image.id !== imageId));
    } else {
      setImages(images.filter((image) => image.id !== imageId));
    }
  };

  const handleFileChange = (event, setImage) => {
    const file = event.target.files[0];
    if (file) {
      setImage({
        file: file,
        preview: URL.createObjectURL(file),
      });
    }
  };

  const handleUpload = async () => {
    if (!albumName || !cardCover || !coverImage) {
      alert("Please provide album name, card cover, and cover image.");
      return;
    }

    setLoading(true);

    const uploadImage = async (image) => {
      const formData = new FormData();
      formData.append("file", image.file);
      formData.append(
        "upload_preset",
        process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
      );
      formData.append("folder", albumName);

      try {
        const response = await axios.post(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
          formData
        );
        return response.data.secure_url;
      } catch (error) {
        console.error("Image upload failed:", error);
        return null;
      }
    };

    const uploadPromises = newImages.map((image) => uploadImage(image));
    const uploadedImageUrls = await Promise.all(uploadPromises);
    const successfulUploads = uploadedImageUrls.filter((url) => url !== null);

    const cardCoverUrl = cardCover.file
      ? await uploadImage(cardCover)
      : cardCover.preview;
    const coverImageUrl = coverImage.file
      ? await uploadImage(coverImage)
      : coverImage.preview;

    if (cardCoverUrl && coverImageUrl) {
      try {
        const updatedImages = [
          ...images.map((img) => img.url),
          ...successfulUploads,
        ]; // Get only URLs

        await axios.put(
          "https://brooklyn-mvc-pattern.vercel.app/albums/update/title",
          {
            _id: albumData._id,
            images: updatedImages,
            albumName: albumName,
            cardCover: cardCoverUrl,
            coverImage: coverImageUrl,
          }
        );

        toast.success("Images uploaded and album updated successfully!");
        setLoading(false);
      } catch (err) {
        console.error("Error updating album in MongoDB:", err);
        toast.error("Error updating album");
        setLoading(false);
      }
    } else {
      toast.error("Failed to upload some images");
      setLoading(false);
    }
  };

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedImages = Array.from([...images, ...newImages]);
    const [removed] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, removed);

    const updatedImages = reorderedImages.filter((img) => img.url); // Keep only images with URLs
    const updatedNewImages = reorderedImages.filter((img) => img.file); // Keep only new images

    setImages(updatedImages);
    setNewImages(updatedNewImages);
  };

  const renderImageGrid = () => {
    const allImages = [...images, ...newImages]; // Combine existing and new images
    const chunkSize = 9; // Set chunk size (number of images per grid)
    const chunks = chunkArray(allImages, chunkSize); // Break images into chunks of chunkSize

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        {chunks.map((chunk, chunkIndex) => (
          <div
            key={chunkIndex}
            className="grid md:grid-cols-2 gap-7 md:py-10 lg:w-[80%] w-[95%] mx-auto"
          >
            {/* First column of the grid */}
            <Droppable droppableId={`column-1-${chunkIndex}`}>
              {(provided) => (
                <div
                  className="grid grid-rows-3 grid-flow-col gap-7"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {chunk.slice(0, 4).map((image, index) => {
                    const globalIndex = chunkIndex * chunkSize + index; // Create a unique index across all chunks
                    const imageId = image.id;
                    const imageSrc = image.preview || image.url;
                    const isNewImage = !!image.file;

                    return (
                      <Draggable
                        key={imageId}
                        draggableId={imageId}
                        index={globalIndex} // Use unique index across all images
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`${
                              index === 0
                                ? "row-span-1 col-span-4 lg:h-[300px] h-[200px]"
                                : index === 3
                                ? "row-span-2 col-span-2 lg:h-[628px] h-[428px]"
                                : "row-span-1 col-span-2 lg:h-[300px] h-[200px]"
                            } overflow-hidden w-full cursor-pointer relative`}
                          >
                            <PhotoProvider>
                              <PhotoView src={imageSrc}>
                                <img
                                  src={imageSrc}
                                  alt={`img-${globalIndex + 1}`}
                                  className="w-full h-[100%] shadow-md border-[1px] border-primary object-cover"
                                  loading="lazy"
                                />
                              </PhotoView>
                            </PhotoProvider>
                            <button
                              className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 z-10"
                              onClick={() =>
                                handleRemoveImage(imageId, isNewImage)
                              } // Correctly passing imageId and isNewImage
                            >
                              &minus;
                            </button>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            {/* Second column of the grid */}
            <Droppable droppableId={`column-2-${chunkIndex}`}>
              {(provided) => (
                <div
                  className="grid grid-rows-3 grid-flow-col gap-7"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {chunk.slice(4).map((image, index) => {
                    const globalIndex = chunkIndex * chunkSize + index + 4; // Create a unique index across all chunks
                    const imageId = image.id;
                    const imageSrc = image.preview || image.url;
                    const isNewImage = !!image.file;

                    return (
                      <Draggable
                        key={imageId}
                        draggableId={imageId}
                        index={globalIndex} // Use unique index across all images
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`${
                              index === 0
                                ? "row-span-1 col-span-2 lg:h-[300px] h-[200px]"
                                : index === 1
                                ? "row-span-1 col-span-4 lg:h-[300px] h-[200px]"
                                : "row-span-1 col-span-2 lg:h-[300px] h-[200px]"
                            } overflow-hidden w-full cursor-pointer relative`}
                          >
                            <PhotoProvider>
                              <PhotoView src={imageSrc}>
                                <img
                                  src={imageSrc}
                                  alt={`img-${globalIndex + 1}`}
                                  className="w-full h-[100%] shadow-md border-[1px] border-primary object-cover"
                                  loading="lazy"
                                />
                              </PhotoView>
                            </PhotoProvider>
                            <button
                              className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 z-10"
                              onClick={() =>
                                handleRemoveImage(imageId, isNewImage)
                              } // Correctly passing imageId and isNewImage
                            >
                              &minus;
                            </button>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        ))}
      </DragDropContext>
    );
  };

  return (
    <div className="image-uploader py-5 min-h-screen border-[1px] border-red-600 dark:bg-slate-600">
      {loading && <OverlayLoading />}
      <div className="w-[95%] md:w-[400px] mx-auto items-center dark:text-gray-100">
        <div className="album-name-input my-4">
          <input
            type="text"
            value={albumName}
            onChange={(e) => setAlbumName(e.target.value)}
            placeholder="Enter Album Name"
            className="border-[1px] border-primary w-[400px] rounded-md px-2 py-1  dark:bg-slate-600 "
          />
        </div>

        <div className="my-4">
          <input
            type="file"
            onChange={(e) => handleFileChange(e, setCardCover)}
            className="border-[1px] border-primary rounded-md px-2 py-1  w-[400px]"
          />
          <p className="text-sm text-gray-500 mt-2 dark:text-gray-100">
            Select Card Cover Image
          </p>
        </div>

        <div className="my-4">
          <input
            type="file"
            onChange={(e) => handleFileChange(e, setCoverImage)}
            className="border-[1px] border-primary rounded-md px-2 py-1 w-[400px] "
          />
          <p className="text-sm text-gray-500 mt-2 dark:text-gray-100">
            Select Cover Image
          </p>
        </div>

        <div {...getRootProps()} className="dropzone cursor-pointer">
          <input {...getInputProps()} />
          <div className="flex items-center gap-2 border-[1px] border-primary rounded-md px-2 py-1 justify-center  dark:text-gray-100">
            <p>Select Your Pictures</p> <IoIosImages className="text-3xl" />
          </div>
        </div>

        <div className="flex justify-center my-5">
          <button
            className="upload-button mt-5 px-5 py-2 bg-blue-600 text-white rounded"
            onClick={handleUpload}
            disabled={loading}
          >
            Upload Images
          </button>
        </div>
      </div>

      {renderImageGrid()}
    </div>
  );
};

export default EditAlbum;
