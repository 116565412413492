import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { FaUser } from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";
import Loading from "../../Shared/Loading";
import { MdArrowBackIosNew, MdOutlineNavigateNext } from "react-icons/md";

const AllUsers = () => {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);

  const url = `https://brooklyn-mvc-pattern.vercel.app/users?page=${page}&size=${size}`;

  const {
    data: { users, count } = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["users", page, size],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });

  //pages

  const pages = Math.ceil(count / size);

  // console.log(messages)

  const nextPage = () => {
    setPage((prevPage) => Math.min(prevPage + 1, pages - 1));
  };

  const prevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  if (isLoading) {
    return <Loading></Loading>;
  }

  const deleteHandler = (user) => {
    const proceed = window.confirm(
      `Are you sure, you want to delete ${user?.name} ?`
    );
    if (proceed) {
      fetch(`https://brooklyn-server.vercel.app/users/${user?._id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount) {
            toast.success("User Delete SuccessFully");
            refetch();
          }
        });
    }
  };

  return (
    <div>
      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead className="">
            <tr>
              <th className="">Serial</th>
              <th>User Name</th>
              <th>Image</th>
              <th>Email</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user, index) => (
              <tr className="hover" key={user._id}>
                <th>{index + 1}</th>
                <td>{user?.name}</td>
                {user?.photoURL == null ? (
                  <>
                    <td>
                      <div className="avatar">
                        <div className="avatar  rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                          <FaUser className="w-10 h-10" />
                        </div>
                      </div>
                    </td>
                  </>
                ) : (
                  <>
                    <td>
                      <div className="avatar">
                        <div className="avatar w-10 h-10 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                          <img src={user?.photoURL} alt="" />
                        </div>
                      </div>
                    </td>
                  </>
                )}

                <td>
                  <a href={`mailto:${user?.email}`} className="text-primary">
                    {user?.email}{" "}
                  </a>
                </td>
                <td>
                  <button
                    onClick={() => deleteHandler(user)}
                    className="btn btn-sm bg-red-600 uppercase text-white"
                  >
                    delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="  pt-5  w-full ">
        <p className="text-center  text-sm font-semibold dark:text-gray-100 ml-3 ">
          Currently Selected page:{" "}
          <span className="text-primary">{page + 1}</span>
        </p>
        <div>
          <div>
            <div className=" py-3 flex justify-center">
              <button
                className="flex items-center pr-2 pl-2 py-1 text-gray-500 ml-2 xl:ml-3 cursor-pointer border-[1px] border-gray-300
                   hover:bg-primary hover:text-white custom-shadow dark:text-white text-sm"
                onClick={prevPage}
                disabled={page === 0}
              >
                <MdArrowBackIosNew className=" font-bold " />{" "}
                <MdArrowBackIosNew className=" -ml-1 " /> Prev
              </button>

              <button
                className="flex items-center  pl-2 py-1 text-gray-500 ml-2 xl:ml-3 cursor-pointer border-[1px] border-gray-300 hover:bg-primary hover:text-white custom-shadow dark:text-white text-sm"
                onClick={nextPage}
                disabled={page === pages - 1}
              >
                Next <MdOutlineNavigateNext className="text-xl" />{" "}
                <MdOutlineNavigateNext className="text-xl -ml-3" />
              </button>

              <select
                className="ml-2 xl:ml-3 dark:bg-gray-400 dark:text-gray-100 bg-white text-gray-500 border-[1px] border-gray-300 rounded-md focus:outline-none px-1 text-sm leading-tight font-medium"
                onChange={(event) => setSize(event.target.value)}
              >
                <option
                  selected
                  disabled
                  className="hidden"
                >{`Page Size ${size}`}</option>

                <option value="6">Page Size 6</option>
                <option value="10">Page Size 10</option>
                <option value="15">Page Size 15</option>
                <option value="20">Page Size 20</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllUsers;
