import { useState } from "react";
import Reviews from "./Reviews";

const CourseDetails = ({ course }) => {
  const [activeTab, setActiveTab] = useState("Overview");

  const renderTabContent = () => {
    switch (activeTab) {
      case "Overview":
        return (
          <section className="mb-10">
            <h2 className="text-2xl font-bold mb-5 dark:text-gray-100">
              Course Description
            </h2>
            <p className="text-gray-600 mb-5 dark:text-gray-100">
              {course?.CourseDescription}
            </p>

            <h3 className="text-xl font-semibold mt-5 mb-3 dark:text-gray-100">
              Course Schedule
            </h3>
            <ul className="list-disc pl-5 space-y-2 text-gray-600  dark:text-gray-100">
              <li>
                <strong>Start Date:</strong> {course?.startDate}
              </li>
              <li>
                <strong>End Date:</strong> {course?.endDate}
              </li>
              <li>
                <strong>Hours:</strong> 83
              </li>
              <li>
                <strong>Class Days:</strong> {course?.description?.[1]}
              </li>
              <li>
                <strong>Opening Hours:</strong> {course?.openingHour}
              </li>
              <li>
                <strong>Duration:</strong> {course?.description?.[0]}
              </li>
            </ul>

            <h3 className="text-xl font-semibold mb-3 mt-5 dark:text-gray-100">
              What You’ll Learn From This Course:
            </h3>
            <ul className="list-disc pl-5 space-y-2 text-gray-600 dark:text-gray-100">
              <li>
                Understand the fundamentals of home healthcare and patient
                support
              </li>
              <li>
                Learn essential personal care skills, including bathing,
                dressing, and feeding assistance
              </li>
              <li>
                Master infection control and hygiene protocols to ensure patient
                safety
              </li>
              <li>
                Develop effective communication skills for working with patients
                and healthcare professionals
              </li>
              <li>
                Gain hands-on experience in assisting patients with mobility and
                daily activities
              </li>
              <li>
                Learn how to monitor vital signs and recognize health concerns
              </li>
              <li>
                Understand proper meal preparation and dietary needs for
                patients
              </li>
              <li>
                Receive supervised practical training to apply your skills in
                real-world scenarios
              </li>
              <li>
                Complete the course and obtain your official Home Health Aide
                (HHA) certification
              </li>
            </ul>

            <h3 className="text-xl font-semibold mt-5 mb-2 dark:text-gray-100">
              Certification
            </h3>
            <p className="text-gray-600 dark:text-gray-100">
              Complete this course and earn your official Home Health Aide (HHA)
              certificate. This certification will equip you with the necessary
              skills and qualifications to start a rewarding career in the home
              healthcare industry.
            </p>
          </section>
        );
      case "Curriculum":
        return (
          <section className="mb-10">
            <h2 className="text-2xl font-bold mb-5 dark:text-gray-100">
              Curriculum
            </h2>
            {course?.curriculum?.map((module, index) => (
              <div
                key={index}
                className=" border-[1px] border-primary dark:border-gray-100 shadow-md rounded-lg mb-5 p-4"
              >
                <h3 className="text-xl font-semibold mb-2 dark:text-gray-100">
                  {module?.title}
                </h3>
                <ul className="space-y-2">
                  <li className="flex justify-between text-lg items-center text-gray-600 mb-2 dark:text-gray-100">
                    <span className="font-bold underline ">
                      Course/Unit Title
                    </span>
                    <span className="font-bold underline text-gray-500 dark:text-gray-100">
                      Hours
                    </span>
                  </li>
                  {module.lessons.map((lesson, idx) => (
                    <li
                      key={idx}
                      className="flex justify-between items-center text-gray-600 border-b-[1px] pb-1"
                    >
                      <span className=" lg:text-base text-sm  dark:text-gray-100">
                        {lesson?.name}
                      </span>
                      <span className="font-medium text-sm text-gray-500 dark:text-gray-100">
                        {lesson?.time}
                      </span>
                    </li>
                  ))}
                  <li className="flex justify-between text-lg items-center text-gray-600 mb-2 dark:text-gray-100">
                    <span className="font-bold">Total</span>
                    <span className="font-bold  text-gray-500 dark:text-gray-100">
                      83 hours
                    </span>
                  </li>
                </ul>
              </div>
            ))}
          </section>
        );
      case "Instructor":
        return (
          <section className="mb-10">
            <h2 className="text-2xl font-bold mb-5 dark:text-gray-100">
              Instructor
            </h2>
            <div className="flex items-center space-x-5">
              <img
                src={course?.instructorImage}
                alt={course?.instructor}
                className="w-20 h-20 rounded-full border border-gray-200"
              />
              <div>
                <h3 className="text-xl font-semibold dark:text-gray-100">
                  {course?.instructor}
                </h3>
                <p className="text-gray-600 dark:text-gray-100 mt-1">
                  {course?.instructorBio ||
                    "Expert in the field with years of experience."}
                </p>
              </div>
            </div>
          </section>
        );
      case "Reviews":
        return (
          <section className="mb-10 ">
            <Reviews course={course} />
          </section>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto p-5">
      {/* Tabs */}
      <div className="border-b mb-8">
        <ul className="flex md:space-x-8 md:text-lg text-sm space-x-5">
          {course?.tabs?.map((tab, index) => (
            <li
              key={index}
              onClick={() => setActiveTab(tab)}
              className={`cursor-pointer pb-2 border-b-2 ${
                activeTab === tab
                  ? "text-primary border-primary font-semibold"
                  : "text-gray-500 hover:text-primary border-transparent dark:text-gray-100"
              }`}
            >
              {tab}
            </li>
          ))}
        </ul>
      </div>

      {/* Tab Content */}
      {renderTabContent()}
    </div>
  );
};

export default CourseDetails;
