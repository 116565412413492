import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import slugify from "slugify";

const CourseCard = ({ course }) => {
  const [isHovered, setIsHovered] = useState(false);

  console.log(course);

  return (
    <div
      className="relative group overflow-hidden bg-white dark:bg-slate-500 rounded-lg shadow-xl hover:shadow-xl transition-shadow duration-300"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Static Card */}
      <div
        className={` ${
          isHovered ? "opacity-0" : "opacity-100"
        } transition-opacity duration-300`}
      >
        <img
          src={course?.img}
          alt={course?.classType}
          className="w-full lg:h-40 2xl:h-52 object-cover rounded-t-lg"
        />
        <div className="absolute top-2 right-2 bg-yellow-500 text-white text-xs px-2 py-1 rounded-full shadow-md">
          83 Hours
        </div>
        <div className=" p-5">
          <h5 className="text-lg  font-bold mt-1 text-yellow-500">
            {course?.classType}
          </h5>
          <h3 className="text-xl font-bold mt-1 dark:text-gray-100">
            {course?.classTitle}
          </h3>
          <div className="text-base text-gray-600 dark:text-gray-100 mt-2">
            <p>
              <strong>Start Date:</strong> {course?.startDate}
            </p>
          </div>
          <div className="text-base text-gray-600 dark:text-gray-100 mt-2">
            <p>
              <strong>End Date:</strong> {course?.endDate}
            </p>
          </div>

          <div className="mt-2 text-base text-gray-600 dark:text-gray-100">
            <p>
              <strong>Opening Hours:</strong> {course?.openingHour}
            </p>
          </div>
          <div className="mt-2 text-base text-gray-600 dark:text-gray-100">
            <p>
              <strong>Class Days:</strong> {course?.description?.[1]}
            </p>
          </div>
          <div className="mt-2 text-base text-gray-600 dark:text-gray-100">
            <p>
              <strong>Fees:</strong> Registration ${course?.registration},
              Tuition ${course?.tuition}, Textbook ${course?.books}
            </p>
          </div>
          <div className="mt-2 text-base text-gray-600 dark:text-gray-100">
            <p>
              <strong>Duration</strong>: {course?.description?.[0]}{" "}
            </p>
          </div>
          <div className="mt-2 text-lg font-bold text-gray-800 dark:text-gray-100">
            Total: $
            {parseInt(`${course?.registration}`) +
              parseInt(`${course?.tuition}`) +
              parseInt(`${course?.books}`)}
          </div>
        </div>
      </div>

      {/* Hover Details */}
      <div
        className={`absolute inset-0 dark:bg-slate-900 bg-[#1BB69B] text-white p-6 rounded-lg transform transition-transform duration-300 ${
          isHovered ? "translate-y-0" : "translate-y-full"
        }`}
      >
        <h5 className="text-xl font-bold">
          {" "}
          <span>{course?.classType}</span> <span>{course?.classTitle}</span>{" "}
        </h5>

        <div className="mt-2 space-y-2">
          <p className="text-lg">
            <strong>Start Date:</strong> {course?.startDate}
          </p>
          <p className="text-lg">
            <strong>End Date:</strong> {course?.endDate}
          </p>
          <p className="text-lg">
            <strong>Hours:</strong> 83 Hours
          </p>
          <p className="text-lg">
            <strong>Schedule:</strong> {course?.description?.[1]}
          </p>
          <p className="text-lg">
            <strong>Opening Hours:</strong> {course?.openingHour}
          </p>

          <p className="text-lg">
            <strong>Duration:</strong> {course?.description?.[0]}
          </p>
          <p className="text-lg">
            <strong>Fees:</strong>{" "}
            <span className="text-base">
              Registration ${course?.registration}, Tuition ${course?.tuition},
              Textbook ${course?.books}
            </span>
          </p>
          <p className="mt-2 font-bold text-lg">
            Total: $
            {parseInt(`${course?.registration}`) +
              parseInt(`${course?.tuition}`) +
              parseInt(`${course?.books}`)}
          </p>
        </div>

        {course?.classType === "Upcoming" ? (
          <>
            <Link
              to={`/classes/${slugify(
                `${course?.classType?.toLowerCase()}-${course?.classTitle?.toLowerCase()}`
              )}`}
              className="mt-6 bg-pink-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-pink-600 inline-block"
            >
              Enroll Now →
            </Link>
          </>
        ) : (
          <>
            <Link
              to={`/classes/${slugify(
                `${course?.classType?.toLowerCase()}-${course?.classTitle?.toLowerCase()}`
              )}`}
              className="mt-6 bg-pink-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-pink-600 inline-block"
            >
              See Details →
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default CourseCard;
