import { useQuery } from "@tanstack/react-query";
import React, { useContext } from "react";
import Loading from "../../Shared/Loading";
import UseEditor from "../../Hooks/UseEditor";
import { AuthContext } from "../../Context/AuthProvider";
import { Link } from "react-router-dom";

const AllOngoingClasses = () => {
  const { user } = useContext(AuthContext);
  const [isEditor] = UseEditor(user?.email);

  const url = `https://brooklyn-mvc-pattern.vercel.app/classes/allClasses/Ongoing`;

  const {
    data: allClasses = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["allClasses", "Ongoing"],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="pt-5">
      <h1 className="text-center text-lg md:text-2xl dark:text-gray-100 font-semibold my-5 ">
        Ongoing Classes
      </h1>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-7 w-[85%] mx-auto">
        {allClasses?.map((course) => (
          <div className="relative group overflow-hidden bg-white dark:bg-slate-500 rounded-lg shadow-xl hover:shadow-xl transition-shadow duration-300">
            {/* Static Card */}
            <div>
              <img
                src={course?.img}
                alt={course?.classType}
                className="w-full lg:h-40 2xl:h-52 object-cover rounded-t-lg"
              />
              <div className="absolute top-2 right-2 bg-yellow-500 text-white text-xs px-2 py-1 rounded-full shadow-md">
                83 Hours
              </div>
              <div className=" p-5">
                <h5 className="text-lg  font-bold mt-1 text-yellow-500">
                  {course?.classType}
                </h5>
                <h3 className="text-xl font-bold mt-1 dark:text-gray-100">
                  {course?.classTitle}
                </h3>
                <div className="text-base text-gray-600 dark:text-gray-100 mt-2">
                  <p>
                    <strong>Start Date:</strong> {course?.startDate}
                  </p>
                </div>
                <div className="text-base text-gray-600 dark:text-gray-100 mt-2">
                  <p>
                    <strong>End Date:</strong> {course?.endDate}
                  </p>
                </div>
                <div className="mt-2 text-base text-gray-600 dark:text-gray-100">
                  <p>
                    <strong>Class Days:</strong> {course?.description?.[1]}
                  </p>
                </div>
                <div className="mt-2 text-base text-gray-600 dark:text-gray-100">
                  <p>
                    <strong>Fees:</strong> Registration ${course?.registration},
                    Tuition ${course?.tuition}, Textbook ${course?.books}
                  </p>
                </div>
                <div className="mt-2 text-base text-gray-600 dark:text-gray-100">
                  <p>
                    <strong>Duration</strong>: {course?.description?.[0]}{" "}
                  </p>
                </div>
                <div className="mt-2 text-lg font-bold text-gray-800 dark:text-gray-100">
                  Total: $
                  {parseInt(`${course?.registration}`) +
                    parseInt(`${course?.tuition}`) +
                    parseInt(`${course?.books}`)}
                </div>
              </div>
            </div>
            {user?.email && isEditor && (
              <div className="flex justify-evenly my-3 mt-5">
                <Link to={`/EditorDashBoard/singleClass/${course?._id}`}>
                  <button className="px-6 py-2 rounded-lg bg-[#00A6B2] btn btn-sm font-medium text-gray-100">
                    Update
                  </button>
                </Link>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllOngoingClasses;
