import React from "react";
import { useQuery } from "@tanstack/react-query";

const Marquee = () => {
  const url = `https://brooklyn-mvc-pattern.vercel.app/classes/allClasses`;

  const {
    data: allClasses = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["allClasses", "Upcoming"],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });

  // function getClassInfo(singleClass) {
  //   return [singleClass?.classType, singleClass?.classTitle, "On", singleClass?.startDate, "," , singleClass?.startDate2, ",", singleClass?.startDate3].join(" ");
  // }

  function getClassInfo(singleClass) {
    const startDates = [
      singleClass?.startDate,
      singleClass?.startDate2,
      singleClass?.startDate3,
    ].filter(Boolean);
    const formattedDates = startDates.map((date) => `${date}`);
    return [
      singleClass?.classType,
      singleClass?.classTitle,
      "On",
      ...formattedDates,
    ].join(" ");
  }

  const classInfoStrings = allClasses?.map(getClassInfo);

  return (
    <div className="text-primary dark:text-gray-200 font-semibold py-1 ">
      {allClasses?.length > 0 && (
        <marquee direction="left" scrollamount="5">
          {classInfoStrings.map((item, index) => (
            <React.Fragment key={index}>
              {index !== 0 && <span className="mx-3 "> :: </span>}
              {item}
            </React.Fragment>
          ))}
        </marquee>
      )}
    </div>
  );
};

export default Marquee;
