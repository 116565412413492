import React, { useState } from "react";
import { Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./FoodChallenges.css";

// Import images
import img13 from "../../../Assets/allVideos/IMG13.webp";
import img14 from "../../../Assets/allVideos/img14.webp";
import img15 from "../../../Assets/allVideos/img15.webp";
import img16 from "../../../Assets/allVideos/img16.webp";
import spicyWings from "../../../Assets/allVideos/spicy_wings.webp";
import jerkChallenge from "../../../Assets/allVideos/img17.webp";
import doubleChallenge from "../../../Assets/allVideos/img18.webp";
import paniPuriChallenge from "../../../Assets/allVideos/img19.webp";
import spicyDouble from "../../../Assets/allVideos/spicy_bouble.webp";
import bbqChicken from "../../../Assets/allVideos/bbq_chiken.webp";

import VideoModal from "./VideoModal";
import { VideoSliderButton } from "./VideoSliderButton";

const allVideos = [
  {
    des: "The Spicy Wings Challenge: Who Will Cry First? 🌶️👀 | Sponsored By Cottage Home Care | Friday Fun Day",
    duration: "6:01",
    img: img16,
    youtubeUrl:
      "https://www.youtube.com/embed/MBCTyo9OmCE?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },

  {
    des: "WHAT JUST HAPPENED! No Way! - Taco Challenge | Sponsored by Cottage Home Care Services | Friday Fun!",
    duration: "5:18",
    img: img15,
    youtubeUrl:
      "https://www.youtube.com/embed/kxZTjxCb5Os?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "Risk or Reward Challenge! | India vs Guyana | $300 On the Line! Sponsored by Cottage Home Care",
    duration: "9:26",
    img: img14,
    youtubeUrl:
      "https://www.youtube.com/embed/ZdJE8sCJFbM?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: " 'Gwaan, give up nuh bredda' | Friday Fun Day Food Challenge |  Brooklyn Institute of Vocational Training ",
    duration: "5:17",
    img: img13,
    youtubeUrl:
      "https://www.youtube.com/embed/2q65v3R-YHA?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "Spicy Wings Challenge | Friday Fun Day Food Challenge | Ft. Singer Vanita aka Poowah | Collaboration",
    duration: "3:51",
    img: spicyWings,
    youtubeUrl:
      "https://www.youtube.com/embed/1AlkjRTfHYY?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "🌶️🔥 Jamaican Jerk Chicken Challenge | Sponsored By Cottage Home Care | Friday Fun Day 🔥🌶️",
    duration: "4:15",
    img: jerkChallenge,
    youtubeUrl:
      "https://www.youtube.com/embed/rrA72WpBAwY?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "🎥 Omardath Maraj & Vanita Willie Judge Trinidadian Doubles Challenge Sponsored by Cottage Home Care🍛",
    duration: "6:18",
    img: doubleChallenge,
    youtubeUrl:
      "https://www.youtube.com/embed/43qCfiNI5Xs?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "Pani Puri Challenge Alert! | Sponsored by Cottage Home Care Services | Friday Fun! 🔥",
    duration: "4:29",
    img: paniPuriChallenge,
    youtubeUrl:
      "https://www.youtube.com/embed/XzCEv810gI4?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "$300 SPICY DOUBLES CHALLENGE: 3 CONTESTANTS, 5 DOUBLES, ONE WINNER! | Sponsored by Cottage Home Care",
    duration: "4:16",
    img: spicyDouble,
    youtubeUrl:
      "https://www.youtube.com/embed/gZXCPT8dIas?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "🔥 $300 BBQ Chicken Chow Mein Challenge! 🍜💰 | Sponsored by Cottage Home Care",
    duration: "3:31",
    img: bbqChicken,
    youtubeUrl:
      "https://www.youtube.com/embed/vFG9kGmzXaE?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
];

export default function App() {
  const [youtubeUrl, setYoutubeUrl] = useState("");

  const [showModal, setShowModal] = useState(false);

  const openModal = (video) => {
    setShowModal(true);
    setYoutubeUrl(video?.youtubeUrl);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="carousel-bg  py-5">
      <div className="w-[100%]">
        <div data-aos="fade-up" data-aos-duration="1500">
          <div className="w-[95%] lg:w-[80%] 2xl:w-[80%] mx-auto dark:text-white mt-[10px] md:mt-[20px] lg:mt-10 ">
            <h1 className=" text-start text-lg lg:text-3xl font-semibold league-spartan text-primary dark:text-gray-100 lg:max-w-[1080px] ">
              {" "}
              Brooklyn Institute of Vocational Training: Fun Day Challenges –
              Where Skills and Community Come Together, Sponsored by Cottage
              Home Care!
            </h1>
            <p className="mt-1 md:mt-3 w-[95%] lg:max-w-[865px]  text-sm md:text-base  lg:leading-loose  text-[16px] open-sans font-normal text-[#373642] dark:text-gray-100 text-justify">
              Get ready for endless fun, thrilling challenges, and non-stop
              laughter! Our Fun Day Challenges are designed to bring students
              and staff together through exciting, culturally inspired
              competitions. It’s the perfect opportunity to build connections,
              create lasting memories, and compete for exciting cash prizes!
            </p>
            <p className="mt-2 2xl:mt-5 text-lg  lg:text-xl 2xl:text-2xl font-semibold text-start league-spartan text-primary dark:text-gray-100">
              Watch Now & Stay Tuned for More Excitement!
            </p>
          </div>
          <div
            className="cottageAllVideo-swiper-page  relative  "
            style={{ overflow: "hidden" }}
          >
            <Swiper
              modules={[Pagination, Autoplay]}
              slidesPerView={3}
              centeredSlides={false}
              spaceBetween={40}
              pagination={{
                type: "fraction",
              }}
              grabCursor={true}
              autoplay={{
                delay: 3000, // Delay between slides in ms (adjust as needed)
                disableOnInteraction: false, // Continue autoplay even after user interaction
              }}
              loop={true} // Enable looping
              className="w-[90%] md:w-[95%] lg:w-[80%] 2xl:w-[80%] xl:h-[290px]  2xl:h-[360px] h-[300px] lg:h-[260px] cottage-all-videos "
              breakpoints={{
                // when window width is >= 320px
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                // when window width is >= 768px
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                // when window width is >= 1024px
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
              }}
            >
              {allVideos?.map((video, index) => (
                <SwiperSlide
                  onClick={() => openModal(video)}
                  className="cottage-video-slider h-[220px] lg:h-[180px]  xl:h-[200px] 2xl:h-[270px] mt-[35px] md:mt-[30px] 2xl:mt-[35px]"
                  key={index}
                  virtualIndex={index}
                >
                  <div
                    className="cottage-video-image  h-[220px] lg:h-[180px] xl:h-[200px] 2xl:h-[270px] relative overflow-hidden"
                    style={{
                      backgroundImage: `url(${video?.img})`,
                      backgroundColor: "transparent",
                      backgroundSize: "cover",
                      width: "100%",
                    }}
                  >
                    {/* Video duration */}
                    <p className="bg-black/85 px-2 py-0.5 text-sm text-white absolute top-2 left-2">
                      {video?.duration}
                    </p>

                    {/* Video description */}
                    <p className="cottage-video-description w-full bg-black/70 text-white text-center p-2 absolute bottom-0 transform translate-y-full transition-transform duration-300 ease-in-out group-hover:translate-y-0">
                      {video.des}
                    </p>
                  </div>
                </SwiperSlide>
              ))}

              <div className="top-0 right-0 absolute">
                <VideoSliderButton />
              </div>
            </Swiper>
          </div>
        </div>

        {showModal ? (
          <VideoModal youtubeUrl={youtubeUrl} onClose={closeModal} />
        ) : null}
      </div>
    </div>
  );
}
