import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Loading from "../../Shared/Loading";
import toast from "react-hot-toast";
import MessageModal from "./MessageModal";

const Enrollment = () => {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userMessage, setUserMessage] = useState(" ");

  const url = `https://brooklyn-mvc-pattern.vercel.app/enrollment?page=${page}&size=${size}`;

  const {
    data: { enrollments, count } = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["enrollment", page, size],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });

  //pages count
  const pages = Math.ceil(count / size);

  //delete email

  const deleteHandler = (message) => {
    const proceed = window.confirm(
      `Are you sure, you want to delete ${message?.name} ?`
    );
    if (proceed) {
      fetch(
        `https://brooklyn-mvc-pattern.vercel.app/enrollment/${message?._id}`,
        {
          method: "DELETE",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          // Access the nested `deletedCount` inside `result`
          console.log(data);
          if (data?.deletedCount > 0) {
            toast.success("Data Deleted Successfully");
            refetch();
          } else {
            toast.error("Failed to delete the Data");
          }
        })
        .catch((error) => {
          console.error("Error during deletion:", error);
          toast.error("An error occurred while deleting the Data");
        });
    }
  };

  const reviewHandler = (message) => {
    fetch(
      `https://brooklyn-mvc-pattern.vercel.app/enrollment/${message?._id}`,
      {
        method: "PUT",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if (data.modifiedCount > 0) {
          toast.success("Review Successful");
          refetch();
          // console.log("true");
        }
      });
  };
  const reverseHandler = (message) => {
    fetch(
      `https://brooklyn-mvc-pattern.vercel.app/enrollment/reverse/${message?._id}`,
      {
        method: "PUT",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if (data.modifiedCount > 0) {
          toast.success("Review Unsuccessful");
          refetch();
          // console.log("true");
        }
      });
  };

  if (isLoading) {
    return <Loading></Loading>;
  }
  return (
    <div>
      {enrollments?.length > 0 ? (
        <>
          <h1 className="my-3 text-xl font-semibold text-gray-500 dark:text-gray-200 text-center">
            {" "}
            All Enrollment Request
          </h1>
          <div className="overflow-x-auto">
            <table className="table w-full">
              <thead className="">
                <tr>
                  <th className="dark:bg-slate-500 dark:text-gray-200">
                    Serial
                  </th>
                  <th className="dark:bg-slate-500 dark:text-gray-200">
                    Date & Time
                  </th>
                  <th className="dark:bg-slate-500 dark:text-gray-200">
                    User Name
                  </th>
                  <th className="dark:bg-slate-500 dark:text-gray-200">
                    Class Name
                  </th>

                  <th className="dark:bg-slate-500 dark:text-gray-200">
                    Email
                  </th>
                  <th className="dark:bg-slate-500 dark:text-gray-200">
                    phone
                  </th>
                  <th className="dark:bg-slate-500 dark:text-gray-200">
                    preferred call Time
                  </th>
                  <th className="dark:bg-slate-500 dark:text-gray-200">
                    Check
                  </th>
                  <th className="dark:bg-slate-500 dark:text-gray-200">
                    message
                  </th>
                  <th className="dark:bg-slate-500 dark:text-gray-200">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {enrollments?.map((message, index) => (
                  <tr className="hover" key={message._id}>
                    <th className="dark:bg-slate-400 dark:text-gray-200">
                      {index + 1}
                    </th>
                    <td className="dark:bg-slate-400 dark:text-gray-200 ">
                      {new Date(message.date).toLocaleString()}{" "}
                    </td>

                    <td className="dark:bg-slate-400 dark:text-gray-200">
                      {" "}
                      {message?.name}{" "}
                    </td>
                    <td className="dark:bg-slate-400 dark:text-gray-200">
                      {" "}
                      {message?.CourseClass}{" "}
                    </td>
                    <td className="dark:bg-slate-400 dark:text-gray-200">
                      {" "}
                      <a
                        href={`mailto:${message?.email}`}
                        className="text-[#CA4757] dark:text-white"
                      >
                        {message?.email}{" "}
                      </a>{" "}
                    </td>
                    <td className="dark:bg-slate-400 dark:text-gray-200">
                      {" "}
                      <a
                        href={`tel:${message?.phone}`}
                        className="text-[#CA4757] dark:text-white"
                      >
                        {message?.phone}{" "}
                      </a>{" "}
                    </td>
                    <td className="dark:bg-slate-400 dark:text-gray-200">
                      {" "}
                      {message?.preferredTime}{" "}
                    </td>

                    {message?.review === "true" ? (
                      <td className="dark:bg-slate-400 dark:text-gray-100 cursor-pointer">
                        <div
                          className="form-control"
                          onClick={() => reverseHandler(message)}
                        >
                          <label className="cursor-pointer label">
                            <input
                              type="checkbox"
                              checked={message?.review === "true"} // ✅ Dynamically set checked
                              className="checkbox checkbox-primary"
                            />
                          </label>
                        </div>
                      </td>
                    ) : (
                      <td className="dark:bg-slate-400 dark:text-gray-100 cursor-pointer">
                        <div
                          className="form-control"
                          onClick={() => reviewHandler(message)}
                        >
                          <label className="cursor-pointer label">
                            <input
                              type="checkbox"
                              checked={message?.review === "true"} // ✅ Dynamically set checked
                              className="checkbox checkbox-primary"
                            />
                          </label>
                        </div>
                      </td>
                    )}

                    <td className="dark:bg-slate-400 dark:text-gray-200">
                      <button
                        className="btn btn-sm bg-[#CA4757] text-gray-100"
                        onClick={() => {
                          setIsModalOpen(true); // ✅ Opens the modal
                          setUserMessage(`${message?.message}`); // ✅ Updates the message state
                        }}
                      >
                        See Message
                      </button>
                    </td>

                    <td className="dark:bg-slate-400 dark:text-gray-200">
                      <button
                        onClick={() => deleteHandler(message)}
                        className="btn btn-sm bg-[#CA4757] text-white"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <p className="text-center mt-5 text-lg font-semibold dark:text-gray-100">
              Currently Selected page:{" "}
              <span className="text-[#CA4757] dark:text-gray-100">
                {page + 1}
              </span>
            </p>
            <div className="pagination my-3 flex justify-center">
              {[...Array(pages).keys()]?.map((number) => (
                <button
                  key={number}
                  className={
                    page === number
                      ? "selected px-3 py-1 ml-3 cursor-pointer border-[1px] custom-shadow1"
                      : "px-3 py-1   text-gray-500 ml-3 cursor-pointer border-[1px] border-gray-300 hover:bg-[#444444] hover:text-white dark:text-gray-200"
                  }
                  onClick={() => setPage(number)}
                >
                  {number + 1}
                </button>
              ))}

              <select
                className="ml-3 bg-white text-gray-500 border-[1px] border-gray-300 rounded-md focus:outline-none px-2 dark:bg-gray-400 dark:text-gray-100"
                onChange={(event) => setSize(event.target.value)}
              >
                <option
                  selected
                  disabled
                  className="hidden"
                >{`Page Size ${size}`}</option>

                <option value="5">Page Size 5</option>
                <option value="10">Page Size 10</option>
                <option value="15">Page Size 15</option>
                <option value="20">Page Size 20</option>
              </select>

              <MessageModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                message={userMessage}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="h-[500px] flex items-center justify-center">
            <div>
              <h1 className="text-2xl text-center text-gray-700 dark:text-gray-100 font-semibold">
                No Enrollment Requests Found
              </h1>
              <p className="text-center text-gray-700 dark:text-gray-100 mt-2">
                Currently, there are no enrollment requests. Once a student
                submits a request, it will appear here. Please check back later.
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Enrollment;
