import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import Loading from "../../../Shared/Loading";
import "./Pagination.css";

const AllMail = () => {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);

  const url = `https://brooklyn-mvc-pattern.vercel.app/mails?page=${page}&size=${size}`;

  const {
    data: { users, count } = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["allmessages", page, size],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });

  //pages count
  const pages = Math.ceil(count / size);

  //delete email

  const deleteHandler = (email) => {
    const proceed = window.confirm(
      `Are you sure, you want to delete "${email?.email}" ?`
    );
    if (proceed) {
      fetch(`https://brooklyn-mvc-pattern.vercel.app/mails/${email?._id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount) {
            toast.success("Mail Delete SuccessFully");
            refetch();
          }
        });
    }
  };

  // console.log(messages)

  if (isLoading) {
    return <Loading></Loading>;
  }
  return (
    <div>
      <h1 className="my-3 text-xl font-semibold text-gray-500 dark:text-gray-200 text-center">
        {" "}
        All Mailing List{" "}
      </h1>

      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead className="">
            <tr>
              <th className="dark:bg-slate-500 dark:text-gray-200">Serial</th>
              <th className="dark:bg-slate-500 dark:text-gray-200">
                Date Time
              </th>

              <th className="dark:bg-slate-500 dark:text-gray-200">Email</th>
              <th className="dark:bg-slate-500 dark:text-gray-200">
                Send Email
              </th>
              <th className="dark:bg-slate-500 dark:text-gray-200">
                Delete Email
              </th>
            </tr>
          </thead>
          <tbody>
            {users?.map((message, index) => (
              <tr className="hover" key={message._id}>
                <th className="dark:bg-slate-400 dark:text-gray-200">
                  {index + 1}
                </th>
                <td className="dark:bg-slate-400 dark:text-gray-200 ">
                  {new Date(message.date).toLocaleString()}{" "}
                </td>

                <td className="dark:bg-slate-400 dark:text-gray-200">
                  {" "}
                  <a
                    href={`mailto:${message?.email}`}
                    className="text-[#CA4757] dark:text-white"
                  >
                    {message?.email}{" "}
                  </a>{" "}
                </td>
                <td className="dark:bg-slate-400 dark:text-gray-200">
                  {" "}
                  <a
                    href={`mailto:${message?.email}`}
                    className="text-[#CA4757] dark:text-white"
                  >
                    {" "}
                    <button className="btn btn-sm bg-[#4DB2EC]  text-white dark:bg-slate-800">
                      send Mail
                    </button>{" "}
                  </a>{" "}
                </td>

                <td className="dark:bg-slate-400 dark:text-gray-200">
                  <button
                    onClick={() => deleteHandler(message)}
                    className="btn btn-sm bg-[#CA4757] text-white"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <p className="text-center mt-5 text-lg font-semibold dark:text-gray-100">
          Currently Selected page:{" "}
          <span className="text-[#CA4757] dark:text-gray-100">{page + 1}</span>
        </p>
        <div className="pagination my-3 flex justify-center">
          {[...Array(pages).keys()]?.map((number) => (
            <button
              key={number}
              className={
                page === number
                  ? "selected px-3 py-1 ml-3 cursor-pointer border-[1px] custom-shadow1"
                  : "px-3 py-1   text-gray-500 ml-3 cursor-pointer border-[1px] border-gray-300 hover:bg-[#444444] hover:text-white dark:text-gray-200"
              }
              onClick={() => setPage(number)}
            >
              {number + 1}
            </button>
          ))}

          <select
            className="ml-3 bg-white text-gray-500 border-[1px] border-gray-300 rounded-md focus:outline-none px-2 dark:bg-gray-400 dark:text-gray-100"
            onChange={(event) => setSize(event.target.value)}
          >
            <option
              selected
              disabled
              className="hidden"
            >{`Page Size ${size}`}</option>

            <option value="5">Page Size 5</option>
            <option value="10">Page Size 10</option>
            <option value="15">Page Size 15</option>
            <option value="20">Page Size 20</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default AllMail;
