import React from "react";
import TestimonialSlider from "./TestimonialSlider";

const Testimonial = () => {
  return (
    <div className="">
      <div className="my-10">
        <h3 className="text-center font-bold text-2xl text-primary md:text-3xl dark:text-gray-100">
          Students Reviews
        </h3>
        <div className="flex justify-center">
          <hr className="bg-primary px-5 mt-2 border-t-[3px] dark:border-gray-100 border-primary w-[80px] lg:w-[180px] mb-5" />
        </div>
        <div
          className="bg-gradient-to-t from-[#c4d4db] to-[#E8F3F8]  dark:from-slate-200 dark:to-slate-400 min-h-[330px] md:min-h-fit 
             p-1 rounded-md w-[83%] md:w-[80%] 2xl:w-[75%]  mx-auto mb-10  lg:mb-20 relative "
          id="carousel-section"
        >
          <div>
            <TestimonialSlider />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
