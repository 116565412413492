import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./TestimonialSlider.css";
import { IoClose } from "react-icons/io5";
import { FaPlay } from "react-icons/fa";
import fiona from "../../../Assets/student reviews/fiona.webp";
import gurujut from "../../../Assets/student reviews/gurujut.webp";
import pamela from "../../../Assets/student reviews/pamela.webp";
import sashagay from "../../../Assets/student reviews/sashagay.webp";
import stefenie from "../../../Assets/student reviews/stefenie.webp";

const TestimonialSlider = () => {
  const [openVideos, setOpenVideos] = useState([]); // Track multiple open videos

  const allData = [
    { videoId: "2_lQyQxU-kA", img: fiona },
    { videoId: "zP9zmIqwH1Y", img: gurujut },
    { videoId: "ya9fI7iTTiY", img: pamela },
    { videoId: "0O73OUAS3UI", img: sashagay },
    { videoId: "muxVDJ6dxDU", img: stefenie },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 688, settings: { slidesToShow: 2 } },
    ],
  };

  const getThumbnail = (videoId) =>
    `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

  const handleVideoClick = (videoId) => {
    if (!openVideos.includes(videoId)) {
      setOpenVideos((prev) => [...prev, videoId]);
    }
  };

  const handleCloseVideo = (videoId) => {
    setOpenVideos((prev) => prev.filter((id) => id !== videoId));
  };

  return (
    <div className="mb-4 md:my-8 2xl:max-w-[1250px] mx-auto">
      <Slider {...settings}>
        {allData.map((data, index) => (
          <div key={index}>
            <div
              className="relative m-[8px] md:m-[20px] cursor-pointer group overflow-hidden rounded-xl shadow-xl transform transition duration-300 hover:scale-105 h-[270px] md:h-[450px]  "
              onClick={() => handleVideoClick(data.videoId)}
            >
              <img
                src={data.img}
                alt="Video Thumbnail"
                className="w-full h-full object-cover"
                loading="lazy"
              />

              {/* Gradient Overlay */}
              <div className="absolute inset-0  group-hover:opacity-100 transition duration-300"></div>

              {/* Play Button */}
              <div className="absolute inset-0 flex items-center justify-center">
                <button className="bg-red-600 text-white p-4 rounded-full flex justify-center items-center text-2xl shadow-lg w-10 h-10 md:w-12 md:h-12 transform transition duration-300 hover:scale-110 hover:shadow-2xl animate-pulse">
                  <FaPlay />
                </button>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      {/* Video Modals */}
      {openVideos.map((videoId) => (
        <div
          key={videoId}
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
        >
          <div className="relative w-11/12 md:w-3/4 lg:max-w-[900px] 2xl:max-w-[1000px]">
            <button
              className="absolute -top-0.5 -right-1 bg-red-600 text-white p-2 w-10 h-10 md:w-12 md:h-12 rounded-full z-50 hover:bg-red-700 transition shadow-xl flex justify-center items-center text-2xl md:hidden"
              onClick={() => handleCloseVideo(videoId)}
            >
              <IoClose />
            </button>
            <iframe
              className="w-full h-64 md:h-96 lg:h-[500px] 2xl:h-[550px] rounded-xl"
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0`}
              title="YouTube Video Player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>

          <button
            className="absolute top-16 md:top-5 right-5 bg-red-600 text-white p-2 w-8 h-8 md:w-12 md:h-12 rounded-full z-50 hover:bg-red-700 transition shadow-xl md:flex justify-center items-center text-2xl hidden "
            onClick={() => handleCloseVideo(videoId)}
          >
            <IoClose />
          </button>
        </div>
      ))}
    </div>
  );
};

export default TestimonialSlider;
