import { useEffect, useState } from "react";

const UseEditor = (email) => {
  const [isEditor, setIsEditor] = useState(false);
  const [isEditorLoading, setEditorLoading] = useState(true);

  useEffect(() => {
    if (!email) {
      setEditorLoading(false); // Prevent infinite loading if email is missing
      return;
    }

    const fetchEditorStatus = async () => {
      try {
        const res = await fetch(
          `https://brooklyn-mvc-pattern.vercel.app/users/editor/${email}`
        );
        const data = await res.json();

        // console.log("Editor Check Response:", data);

        if (data && typeof data.isEditor !== "undefined") {
          setIsEditor(data.isEditor);
        } else {
          console.error("Unexpected API response structure:", data);
          setIsEditor(false);
        }
      } catch (error) {
        console.error("Error fetching editor status:", error);
        setIsEditor(false);
      } finally {
        setEditorLoading(false);
      }
    };

    fetchEditorStatus();
  }, [email]);

  return [isEditor, isEditorLoading];
};

export default UseEditor;
