import React from "react";
import { IoMdClose } from "react-icons/io";

const MessageModal = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null; // Prevents rendering when not open

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-[90%] md:w-[400px] lg:w-[600px]  relative">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 bg-gray-200 dark:bg-gray-700 p-2 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600"
        >
          <IoMdClose className="text-xl text-gray-700 dark:text-white" />
        </button>

        {/* Modal Title */}
        <h2 className="text-xl font-bold mb-4 dark:text-white">
          Message From user{" "}
        </h2>

        {/* User Message Content */}
        <p
          style={{ whiteSpace: "pre-line" }}
          className="text-gray-700 dark:text-gray-300"
        >
          {message || "No message available."}
        </p>
      </div>
    </div>
  );
};

export default MessageModal;
