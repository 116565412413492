import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  LabelList,
} from "recharts";
import Loading from "../../../Shared/Loading";

const Rechart = () => {
  const [newData, setNewData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const url = ` https://brooklyn-mvc-pattern.vercel.app/visitors/monthlyVisitors?year=${selectedYear}`;

  const {
    data: data = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["monthlyVisitors", selectedYear],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });

  // console.log(data)

  useEffect(() => {
    if (data?.length > 0) {
      const updatedData = data?.map((da, index) => {
        if (da?.month === "July, 2023") {
          return {
            ...da,
            visitors: da.visitors + 225,
            key: index,
          };
        } else {
          return {
            ...da,
            key: index,
          };
        }
      });
      setNewData(updatedData);
    }
  }, [data]);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    refetch(); // This will trigger a refetch of the data with the new year
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div style={{ marginBottom: "10px" }}>
        <label className="dark:text-gray-100 " htmlFor="yearPicker">
          Select Year:{" "}
        </label>
        <select
          className="border px-2 py-0.5 border-gray-400 dark:bg-slate-500 rounded-sm dark:text-gray-100"
          id="yearPicker"
          value={selectedYear}
          onChange={handleYearChange}
        >
          {[...Array(10)].map((_, i) => {
            const year = new Date().getFullYear() - i;
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </select>
      </div>
      <ResponsiveContainer width={"100%"} height={300}>
        <BarChart
          data={newData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="visitors" fill="#82ca9d">
            <LabelList dataKey="visitors" position="top" />
          </Bar>
          <Bar dataKey="users" fill="#8884d8">
            <LabelList dataKey="users" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Rechart;
