import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { IoMdClose } from "react-icons/io";
import OverlayLoading from "../../Shared/OverlayLoading";

const EnrollModal = ({ onClose, course }) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const date = new Date();

  const emailSend = async (info) => {
    try {
      const response = await fetch(
        "https://brooklyn-mvc-pattern.vercel.app/enrollment/email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(info),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit enrollment request");
      }

      const result = await response.json();
      console.log("Enrollment Successful:", result);

      reset(); // Reset the form after submission

      toast.success("Message sent successfully");
      onClose(); // Close the modal
      setLoading(false);
    } catch (error) {
      console.error("Error submitting form:", error.message);
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);

    const info = {
      CourseClass: `${course?.startDate} of ${course?.classTitle}`,
      date: date,
      name: data.name,
      email: data.email,
      phone: data.phone,
      message: data.message,
      preferredTime: data.preferredTime,
    };

    try {
      const response = await fetch(
        "https://brooklyn-mvc-pattern.vercel.app/enrollment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(info),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit enrollment request");
      }

      const result = await response.json();
      console.log("Enrollment Successful:", result);
      emailSend(info);
    } catch (error) {
      console.error("Error submitting form:", error.message);
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      {loading && <OverlayLoading />}
      <div className="bg-white dark:bg-slate-400 p-6 rounded-lg shadow-lg w-[95%] md:w-96 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-3 bg-black text-white px-1 py-1 rounded-full"
        >
          <IoMdClose className="text-xl" />
        </button>
        <h2 className="text-2xl font-bold mb-4 dark:text-white">
          Enroll Request
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div>
            <label className="block text-sm font-medium dark:text-gray-100">
              Name
            </label>
            <input
              type="text"
              {...register("name", { required: "Name is required" })}
              className="w-full px-3 py-2 border rounded-lg outline-none"
            />
            {errors.name && (
              <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium dark:text-gray-100">
              Email
            </label>
            <input
              type="email"
              {...register("email", {
                required: "Email is required",
              })}
              className="w-full px-3 py-2 border rounded-lg outline-none"
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">
                {errors.email.message}
              </p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium dark:text-gray-100">
              Phone Number
            </label>
            <input
              type="tel"
              {...register("phone", {
                required: "Phone number is required",
                pattern: {
                  value: /^\(?([0-9]{3})\)?[-.● ]?([0-9]{3})[-.● ]?([0-9]{4})$/,
                  message: "Please enter a valid US phone number",
                },
              })}
              className="w-full px-3 py-2 border rounded-lg outline-none"
            />
            {errors.phone && (
              <p className="text-red-500 text-sm mt-1">
                {errors.phone.message}
              </p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium dark:text-gray-100">
              Message
            </label>
            <textarea
              {...register("message", { required: "Message is required" })}
              className="w-full px-3 py-2 border rounded-lg outline-none"
            />
            {errors.message && (
              <p className="text-red-500 text-sm mt-1">
                {errors.message.message}
              </p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium dark:text-gray-100">
              Preferred Time to Call
            </label>
            <select
              {...register("preferredTime", {
                required: "Please select a time",
              })}
              className="w-full px-3 py-2 border rounded-lg outline-none"
            >
              <option value="">Select Time</option>
              <option value="morning">Morning (9 AM - 12 PM)</option>
              <option value="afternoon">Afternoon (12 PM - 3 PM)</option>
              <option value="evening">Evening (3 PM - 6 PM)</option>
            </select>
            {errors.preferredTime && (
              <p className="text-red-500 text-sm mt-1">
                {errors.preferredTime.message}
              </p>
            )}
          </div>

          <button
            type="submit"
            className="w-full bg-teal-500 text-white px-4 py-2 rounded-lg font-bold hover:bg-teal-600"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default EnrollModal;
