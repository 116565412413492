import { useEffect, useState } from "react";

const useAdmin = (email) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAdminLoading, setAdminLoading] = useState(true);

  useEffect(() => {
    if (!email) {
      setAdminLoading(false); // Prevent infinite loading if email is missing
      return;
    }

    const fetchAdminStatus = async () => {
      try {
        const res = await fetch(
          `https://brooklyn-mvc-pattern.vercel.app/users/admin/${email}`
        );
        const data = await res.json();

        // console.log("Admin Check Response:", data);

        if (data && typeof data.isAdmin !== "undefined") {
          setIsAdmin(data.isAdmin);
        } else {
          console.error("Unexpected API response structure:", data);
          setIsAdmin(false);
        }
      } catch (error) {
        console.error("Error fetching admin status:", error);
        setIsAdmin(false);
      } finally {
        setAdminLoading(false);
      }
    };

    fetchAdminStatus();
  }, [email]);

  return [isAdmin, isAdminLoading];
};

export default useAdmin;
