import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { RouterProvider } from "react-router-dom";
import ScrollButton from "./Pages/ScrollButton/ScrollButton";
import router from "./Routes/routes";

function App() {
  const localCount = sessionStorage.getItem("brooklynCount");

  useEffect(() => {
    AOS.init();
    AOS.refresh();

    if (!sessionStorage.getItem("brooklynCount")) {
      fetch("https://brooklyn-mvc-pattern.vercel.app/visitors/newCount", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ count: 1 }),
      })
        .then((res) => res.json())
        .then((data) => {
          //  console.log(data)
          sessionStorage.setItem("brooklynCount", true);
        });
    }
  }, [localCount]);

  return (
    <div>
      <RouterProvider router={router}></RouterProvider>
      <Toaster></Toaster>
      <ScrollButton></ScrollButton>
    </div>
  );
}

export default App;
