import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../Context/AuthProvider";
import UseEditor from "../Hooks/UseEditor";
import Loading from "../Shared/Loading";

const EditorRoute = ({ children }) => {
  const { user, loading } = useContext(AuthContext);
  const [isEditor, isEditorLoading] = UseEditor(user?.email);
  const location = useLocation();

  //   console.log("EditorRoute:", { user: user?.email, isEditor, isEditorLoading });

  if (loading || isEditorLoading) {
    return <Loading />;
  }

  if (user && isEditor) {
    return children;
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default EditorRoute;
