import React from "react";
import { BsClockFill } from "react-icons/bs";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";

const UpdateTime = () => {
  const url = "https://brooklyn-mvc-pattern.vercel.app/classes/countDown";

  const {
    data: countDown = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["allClasses", "Upcoming"],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });

  return (
    <div>
      <li className="my-1 dark:text-gray-100 text-lg text-center">
        <Link
          to={`countdown/${countDown?.[0]?._id}`}
          className="flex items-center gap-3"
        >
          <BsClockFill></BsClockFill> Update The Countdown
        </Link>
      </li>
    </div>
  );
};

export default UpdateTime;
