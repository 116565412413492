import React from "react";

import { NavLink } from "react-router-dom";
import shape from "../../../src/Assets/shape/shape-13.png";
import shape2 from "../../../src/Assets/shape/breadcrumb-shape-2.png";
import shape3 from "../../../src/Assets/shape/shape-07-1.png";
import { MdKeyboardArrowRight } from "react-icons/md";
import "./ClassBanner.css";

const ClassBanner = () => {
  const pricingInfo = `PROGRAM FEE: HOURS: 83, TUITION: $499 (You can pay in Installments), 
  TEXTBOOK: $50, REGISTRATION (NON-REFUNDABLE): $49, TOTAL: $598. REQUIREMENTS: A valid ID, 
  High School Diploma (OPTIONAL).`;

  return (
    <div>
      <div className="relative bg-[#fce7eb] dark:bg-slate-700 h-[230px] overflow-hidden">
        {/* Decorative Images */}

        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Layer_1"
          version="1.1"
          viewBox="0 0 415 411"
          style={{ width: "370px", height: "370px" }}
          className="absolute -top-[220px] transform -translate-x-1/2 left-[150px] hidden lg:block "
        >
          <circle
            cx="207.69"
            cy="205.49"
            r="199.18"
            fill="none"
            strokeWidth="0.5"
            className="stroke-gray-400"
          />
        </svg>

        <img
          src={shape}
          alt="new-shape"
          className="spin-animation absolute top-[40px] left-[100px] hidden lg:block w-[150px]"
        />

        <img
          src={shape2}
          alt="new-shape"
          className=" absolute top-[100px] right-[170px] hidden lg:block float-animation"
        />
        <img
          src={shape3}
          alt="new-shape"
          className=" absolute -top-[40px] -right-[95px] hidden lg:block "
        />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Layer_1"
          version="1.1"
          viewBox="0 0 415 411"
          style={{ width: "480px", height: "480px" }}
          className=" absolute -bottom-[200px] -right-[200px] transform   hidden lg:block "
        >
          <circle
            cx="207.69"
            cy="205.49"
            r="199.18"
            fill="none"
            strokeWidth="0.5"
            className="stroke-gray-400"
          />
        </svg>

        <div className="container mx-auto px-4 h-[195px] flex justify-center items-center ">
          <div className="text-center">
            {/* Title */}
            <h1 className="text-2xl lg:text-4xl font-bold text-primary playrify">
              All of Our Programs
            </h1>
            {/* Breadcrumb */}
            <nav className="mt-3 text-base lg:text-lg text-gray-600 dark:text-white">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "text-secondary font-bold"
                    : "text-gray-600 hover:text-gray-800 dark:text-gray-100"
                }
              >
                Home
              </NavLink>
              <span className="mx-2 text-xl font-semibold">
                <MdKeyboardArrowRight className="inline text-2xl" />
              </span>
              {/* Courses Link */}
              <NavLink
                to="/classes"
                className={({ isActive }) =>
                  isActive
                    ? "text-primary font-bold"
                    : "text-gray-600 hover:text-gray-800"
                }
              >
                All Classes
              </NavLink>
            </nav>
          </div>
        </div>

        <div className="z-3">
          <marquee
            direction="left"
            scrollamount="5"
            className=" dark:text-gray-200 font-semibold py-1 px-4 border-[1px] border-primary px-10 w-[105%] mx-auto block  shadow-md "
          >
            {pricingInfo}
          </marquee>
        </div>
      </div>
    </div>
  );
};

export default ClassBanner;
