import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { CgMenuGridR } from "react-icons/cg";
import { AuthContext } from "../../../Context/AuthProvider";
import useAdmin from "../../../Hooks/UseAdmin";
import useTitle from "../../../Hooks/useTitle";
import { LiaReadme } from "react-icons/lia";
import Footer from "../../Footer/Footer";
import { NavBar } from "../../NavBar/NavBar";
import { MdOutlineDashboard } from "react-icons/md";
import { AiFillFile, AiFillMail, AiOutlineHome } from "react-icons/ai";
import { SiGooglemessages } from "react-icons/si";
import { FaBookOpen, FaBookReader, FaGraduationCap } from "react-icons/fa";
import Loading from "../../../Shared/Loading";
import UpdateTime from "./UpdateTime/UpdateTime";
import { toast } from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const EditorLayout = () => {
  useTitle("EditorDashBoard");
  const [drawer, setDrawer] = useState(false);

  const url = `https://brooklyn-server-v4.vercel.app/enrollment`;

  const { data = {}, isLoading } = useQuery({
    queryKey: ["enrollment"],
    queryFn: async () => {
      const response = await axios.get(url);
      return response.data; // ✅ Directly return the response data
    },
    refetchInterval: 3000, // 🔄 Auto-fetch every 6 seconds
  });

  // Ensure enrollments is always an array
  const enrollments = Array?.isArray(data?.enrollments)
    ? data?.enrollments
    : [];
  const uncheckedCount =
    data?.enrollments?.filter((e) => !e.review || e.review === "").length || 0;

  console.log("Unchecked Enrollment Count:", uncheckedCount);

  if (isLoading) {
    return <Loading></Loading>;
  }

  return (
    <div className="min-h-screen dark:bg-slate-600">
      <div className="sticky top-0 z-50">
        <NavBar></NavBar>
        <div className="my-3 block lg:hidden  ">
          <label
            onClick={() => setDrawer(!drawer)}
            htmlFor="dashboard-drawer"
            className="drawer-button text-4xl text-primary lg:hidden"
          >
            <CgMenuGridR />
          </label>
        </div>
      </div>
      <div className="drawer lg:drawer-open">
        <input
          id="dashboard-drawer"
          type="checkbox"
          className="drawer-toggle"
        />
        <div className="drawer-content">
          <Outlet></Outlet>
        </div>
        <div
          className={`drawer-side ${
            drawer ? "block" : "hidden"
          } lg:border-2 dark:border-[#E5E7EB] w-[70%] lg:w-fit bg-white lg:bg-[#EBF8F9] lg:shadow-lg dark:bg-slate-600 dark:text-gray-100`}
        >
          <label
            htmlFor="dashboard-drawer"
            aria-label="close sidebar"
            className=" drawer-overlay "
          ></label>

          {
            <ul className="menu p-4 w-80 text-base-content mt-5 ">
              <li className="my-1 dark:text-gray-100 text-lg text-center">
                <Link to="/" className="flex items-center gap-4">
                  <AiOutlineHome></AiOutlineHome> Home
                </Link>
              </li>
              <li className="my-1 dark:text-gray-100 text-lg text-center">
                <Link to="/EditorDashBoard" className="flex items-center gap-4">
                  <MdOutlineDashboard></MdOutlineDashboard> Dash Board
                </Link>
              </li>

              <li className="my-1 dark:text-gray-100 text-lg text-center">
                <Link
                  to="enrollment"
                  className={`flex items-center gap-3 ${
                    uncheckedCount > 0
                      ? "text-red-500 font-bold animate-pulse"
                      : "text-gray-700 dark:text-gray-100"
                  }`}
                >
                  <LiaReadme /> Enrollment Requests
                  {uncheckedCount > 0 && (
                    <span className="ml-2 bg-red-500 text-white text-xs font-semibold px-2 py-1 rounded-full">
                      {uncheckedCount}
                    </span>
                  )}
                </Link>
              </li>

              <li className="my-1 dark:text-gray-100 text-lg text-center">
                <Link
                  to="/EditorDashBoard/UpcomingSchedules"
                  className="flex items-center gap-4"
                >
                  <FaBookOpen /> Update Upcoming Classes
                </Link>
              </li>
              <li className="my-1 dark:text-gray-100 text-lg text-center">
                <Link
                  to="/EditorDashBoard/OngoingSchedules"
                  className="flex items-center gap-4"
                >
                  <FaBookOpen /> Update Ongoing Classes
                </Link>
              </li>
            </ul>
          }
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default EditorLayout;
