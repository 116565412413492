import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./ViewPdf.css";
import UpcomingNotice from "../../ClassNotice/UpcomingNotice";
import OngoingNotice from "../../ClassNotice/OngoingNotice";
import HolidayNotice from "../../ClassNotice/HolidayNotice";
import UpdatePricingNotice from "../../ClassNotice/UpdatePricingNotice";

function ViewPdf() {
  //   useTitle("NoticeBoard");
  //   const [pdfs, setPdfs] = useState([]);
  //   const [load, setLoad] = useState(false);
  //   const { user } = useContext(AuthContext);
  //   const [isAdmin] = useAdmin(user?.email);

  //   useEffect(() => {
  //     fetchPdfs();
  //   }, [load]);

  //   const fetchPdfs = async () => {
  //     try {
  //       const response = await axios.get(
  //         "https://brooklyn-mvc-pattern.vercel.app/pdfs"
  //       );
  //       setPdfs(response.data);
  //     } catch (error) {
  //       console.error("Error fetching PDFs", error);
  //     }
  //   };

  //   const viewPdf = (pdfData) => {
  //     const buffer = new Uint8Array(pdfData.data.data);
  //     const blob = new Blob([buffer], { type: "application/pdf" });
  //     const pdfUrl = URL.createObjectURL(blob);

  //     window.open(pdfUrl, "_blank");
  //   };

  //   const deleteHandler = (pdf) => {
  //     const proceed = window.confirm(
  //       `Are you sure, you want to delete ${pdf?.filename} ?`
  //     );
  //     if (proceed) {
  //       fetch(`https://brooklyn-mvc-pattern.vercel.app/pdfs/${pdf?._id}`, {
  //         method: "DELETE",
  //       })
  //         .then((res) => res.json())
  //         .then((data) => {
  //           toast.success(data?.message);
  //           // console.log(data)
  //           setLoad(!load);
  //         });
  //     }
  //   };

  const today = Date.now();

  const notices = [
    {
      name: "Upcoming Classes Schedule",
      date: today,
      notice: <UpcomingNotice />,
    },
    {
      name: "Ongoing Classes Schedule",
      date: today,
      notice: <OngoingNotice />,
    },
    {
      name: "Class Fee and Requirements Notice",
      holiday: "September 24, 20204",
      notice: <UpdatePricingNotice />,
    },
    // {
    //   name: "Holiday Notice",
    //   holiday: "May 27, 20204",
    //   notice:  <HolidayNotice/>
    // }
  ];

  return (
    <div className="   dark:bg-slate-600 pb-16 w-[95%] mx-auto py-5">
      <h1 className="md: text-2xl lg:text-4xl text-center font-bold my-3 text-gray-600 dark:text-gray-200">
        All Notices from the Brooklyn Institute
      </h1>

      <div>
        {notices.length > 0 && (
          <div className="overflow-x-auto text-center ">
            <table className="table w-full mt-5">
              <thead>
                <tr>
                  <th className="dark:bg-slate-500 dark:text-gray-100 ">
                    Serial
                  </th>
                  <th className="dark:bg-slate-500 dark:text-gray-100 ">
                    Notice Name
                  </th>
                  <th className="dark:bg-slate-500 dark:text-gray-100 ">
                    {" "}
                    Date
                  </th>
                  <th className="dark:bg-slate-500 dark:text-gray-100 ">
                    View Notice
                  </th>
                  {/* {isAdmin && (
                    <th className="dark:bg-slate-500 dark:text-gray-100 ">
                      Delete Notice
                    </th>
                  )} */}
                </tr>
              </thead>

              <tbody>
                {notices?.map((pdf, index) => (
                  <tr className="hover" key={index}>
                    <th className="text-xs md:text-base dark:bg-slate-400 dark:text-gray-100">
                      {index + 1}
                    </th>
                    <td className="text-xs md:text-base font-semibold dark:bg-slate-400 dark:text-gray-100">
                      {" "}
                      {pdf?.name}
                    </td>
                    {pdf?.date ? (
                      <>
                        <td className="text-xs md:text-base dark:bg-slate-400 dark:text-gray-100">
                          {new Date(pdf.date).toLocaleString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="text-xs md:text-base dark:bg-slate-400 dark:text-gray-100">
                          {pdf?.holiday}
                        </td>
                      </>
                    )}

                    <td className="text-xs md:text-base dark:bg-slate-400 dark:text-gray-100">
                      {/* {" "}
                      <button
                        onClick={() => viewPdf(pdf)}
                        className="btn btn-sm"
                      >
                        View Notice
                      </button> */}
                      {pdf?.notice}
                    </td>

                    {/* {isAdmin && (
                      <td className="text-xs md:text-base dark:bg-slate-400 dark:text-gray-100">
                        <button
                          onClick={() => deleteHandler(pdf)}
                          className="btn btn-sm bg-primary text-white"
                        >
                          Delete Notice
                        </button>
                      </td>
                    )} */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {/* {pdfs <= 0 && (
          <div className="my-5">
            <Loading></Loading>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default ViewPdf;
