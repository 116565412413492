import React, { useContext, useState } from "react";
import "./courseCard.css";

import ClassModal from "../Classes/ClassModal";
import { useQuery } from "@tanstack/react-query";
import { AuthContext } from "../../Context/AuthProvider";
import useAdmin from "../../Hooks/UseAdmin";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import Loading from "../../Shared/Loading";
import CourseCard from "../ClassNotice/CourseCard";

const OnGoingClasses = () => {
  const { user } = useContext(AuthContext);
  const [isAdmin] = useAdmin(user?.email);

  const url = `https://brooklyn-mvc-pattern.vercel.app/classes/allClasses/Ongoing`;

  const {
    data: allClasses = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["allClasses", "Ongoing"],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });
  // console.log(allClasses)

  const deleteHandler = (singleClass) => {
    // console.log(setNewClass)
    const proceed = window.confirm(
      `Are you sure, you want to delete "${singleClass?.classTitle}" ?`
    );
    if (proceed) {
      fetch(
        `https://brooklyn-mvc-pattern.vercel.app/classes/${singleClass?._id}`,
        {
          method: "DELETE",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount) {
            toast.success("class Delete SuccessFully");
            refetch();
          }
        });
    }
  };

  return (
    <div className="mb-20 ">
      <div className="md:mt-14  text-center w-[95%] md:w-[50%] mx-auto">
        <h1 className=" playrify text-2xl md:text-3xl font-semibold text-primary dark:text-gray-100">
          Ongoing Classes
        </h1>
        <div className="flex justify-center mt-3">
          <hr className="w-14 border-t-[1px] border-[#CA4757] " />
        </div>
        {/* <p className="Poppins mt-5 md:text-xl leading-relaxed font-medium dark:text-gray-100">
        Brooklyn Institute of Vocational Training provides ongoing courses as part of comprehensive programs. The Schedules of ongoing classes are:
        </p> */}
      </div>
      {isLoading && (
        <>
          <div className="my-5">
            <Loading></Loading>
          </div>
        </>
      )}

      {/* three card start  */}
      <div className="w-[93%] md:w-[95%] lg:w-[80%] 2xl:w-[70%] mx-auto ">
        {allClasses?.length === 3 && (
          <>
            <div
              className="grid md:grid-cols-2 lg:grid-cols-3 card-grid gap-7 mt-10 "
              // data-aos="zoom-in"
              // data-aos-duration="1500"
            >
              {allClasses?.map((singleClass, index) => (
                <div key={index}>
                  <CourseCard course={singleClass} />
                  {user?.email && isAdmin && (
                    <div className="flex justify-evenly mt-5">
                      <Link to={`/dashboard/singleClass/${singleClass?._id}`}>
                        <button className="px-6 py-2 rounded-lg bg-[#00A6B2] btn btn-sm font-medium text-gray-100">
                          Update
                        </button>
                      </Link>

                      <button
                        onClick={() => deleteHandler(singleClass)}
                        className="px-6 py-2 btn btn-sm rounded-lg bg-primary font-medium text-gray-100"
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>

            {/* three card end  */}
          </>
        )}
      </div>

      {/* <ClassModal newClass={newClass}></ClassModal> */}
    </div>
  );
};

export default OnGoingClasses;
