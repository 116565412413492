import axios from "axios";
import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { BiUserCircle } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";
import { IoSendSharp } from "react-icons/io5";
import { useQuery } from "@tanstack/react-query";
import SkeletonLoading from "../../Shared/SkeletonLoading";

const GuestConversation = () => {
  const userEmail = localStorage.getItem("userEmail");
  const chatDivRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const date = Date.now();

  const clearSession = () => {
    const proceed = window.confirm(
      `Are you sure, you want to clear your session ? All of data will be deleted`
    );
    if (proceed) {
      localStorage.removeItem("userEmail");
      localStorage.removeItem("newSession");
    }
  };

  const updateTime = (lastMessage) => {
    const info = {
      date,
      email: userEmail,
      message: lastMessage,
    };

    fetch(`https://brooklyn-mvc-pattern.vercel.app/users/time`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(info),
    })
      .then((res) => res.json())
      .then((data) => {
        //    console.log(data)
        if (data.acknowledged) {
          toast.success("message send successfully");
        }
      });
  };

  const { isLoading, data, refetch } = useQuery({
    queryKey: ["chats"], // Changed to an array
    queryFn: async () => {
      const { data } = await axios.get(
        `https://brooklyn-mvc-pattern.vercel.app/chats/allChats?email=${userEmail}`
      );
      return data;
    },
    refetchInterval: 5000,
  });

  useEffect(() => {
    // Use setTimeout to scroll after a short delay (you can adjust the delay)
    setTimeout(() => {
      chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
    }, 100); // Adjust the delay as needed
  }, [data]);

  let messageLength = data?.length;

  if (messageLength && localStorage.getItem("totalChat")) {
    if (messageLength > localStorage.getItem("totalChat")) {
      localStorage.setItem("totalChat", messageLength);
    }
  }

  const submitHandler = (allData) => {
    const lastMessage = allData?.message;

    if (data) {
      const message = {
        firstName: data?.[0]?.firstName,
        photoURL: data?.[0]?.photoURL,
        email: data?.[0]?.email,
        time: date,
        messages: allData?.message,
      };
      fetch("https://brooklyn-mvc-pattern.vercel.app/chats", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(message),
      })
        .then((res) => res.json())
        .then((data) => {
          //    console.log(data)
          if (data.acknowledged) {
            updateTime(lastMessage);

            if (messageLength) {
              localStorage.setItem("totalChat", messageLength + 1);
            }
            reset();

            refetch();
          }
        });
    }
  };

  return (
    <div className="dark:bg-slate-500">
      <div className="pb-10 w-[80%] mx-auto min-h-screen dark:bg-slate-600">
        {data?.length && (
          <div className=" flex items-center gap-4 sticky top-0 bg-slate-600 dark:bg-neutral-700 px-4 py-1 mb-2">
            {data?.[0]?.photoURL && (
              <img
                src={data?.[0]?.photoURL}
                alt={data?.[0]?.firstName}
                className="w-12 h-12 rounded-full"
              />
            )}

            {!data?.[0]?.photoURL && (
              <BiUserCircle className="w-12 h-12 text-white"></BiUserCircle>
            )}
            <p className="text-2xl font-semibold text-gray-100">
              {data?.[0]?.firstName}
            </p>

            <div
              className="ml-10 text-gray-100 cursor-pointer
                        text-3xl"
              onClick={clearSession}
            >
              <FiLogOut></FiLogOut>
            </div>
          </div>
        )}

        <div
          className="px-6 pb-6 big-body h-[55vh] overflow-y-scroll "
          ref={chatDivRef}
        >
          {isLoading && (
            <div className="mt-5">
              <SkeletonLoading></SkeletonLoading>
            </div>
          )}

          {data?.map((message) => (
            <div key={message?._id} className="my-2">
              {!message?.role && (
                <p className="flex items-center gap-3 ">
                  {message?.photoURL && (
                    <img
                      src={message?.photoURL}
                      alt=""
                      className="w-6 h-6 rounded-full"
                    />
                  )}
                  {!message?.photoURL && (
                    <BiUserCircle className="w-6 h-6 rounded-full"></BiUserCircle>
                  )}

                  <span className="bg-gray-400 text-white px-3 py-1.5 rounded-md shadow-md  ">
                    {" "}
                    {message?.messages}
                  </span>
                </p>
              )}
              {message?.role && (
                <p className="flex items-center gap-3 justify-end ">
                  <span className="bg-primary text-white px-3 py-1.5 rounded-md shadow-md ">
                    {" "}
                    {message?.messages}
                  </span>
                  <img
                    src={message?.photoURL}
                    alt=""
                    className="w-6 h-6 rounded-full"
                  />
                </p>
              )}
            </div>
          ))}
        </div>

        <div className="mt-5">
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className=" w-[90%] mx-auto relative ">
              <input
                {...register("message", { required: "message is required" })}
                type="text"
                className=" px-4 py-2 dark:bg-slate-500 bg-slate-400 focus:outline-none  rounded-md  w-[100%]  text-white focus:bg-slate-400  "
              />

              {errors.message && (
                <p className="text-red-600">{errors.message.message}</p>
              )}
              <button>
                <IoSendSharp className="absolute right-3 bottom-2 text-purple-900 text-2xl "></IoSendSharp>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GuestConversation;
