import { useContext, useState } from "react";
import { FaRegStar } from "react-icons/fa";
import { IoChevronDown, IoChevronUp, IoStarSharp } from "react-icons/io5";
import axios from "axios";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import AllReviews from "./AllReviews";
import Loading from "../../Shared/Loading";
import { AuthContext } from "../../Context/AuthProvider";
import useAdmin from "../../Hooks/UseAdmin";

const Reviews = ({ course }) => {
  const { user } = useContext(AuthContext);
  // console.log(user)
  const [isAdmin] = useAdmin(user?.email);

  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    star: 5, // Default rating
    comment: "",
  });

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(3);

  // Handle input change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle star rating click
  const handleStarClick = (rating) => {
    setFormData({ ...formData, star: rating });
  };

  //pages

  const url = `https://brooklyn-mvc-pattern.vercel.app/reviews?page=${page}&size=${size}`;

  const {
    data: { allReviews, count } = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["reviews", page, size],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });

  //   console.log(allReviews);
  const pages = Math.ceil(count / size);

  // console.log(messages)

  const nextPage = () => {
    setPage((prevPage) => Math.min(prevPage + 1, pages - 1));
  };

  const prevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const allReviewsUrl = `https://brooklyn-mvc-pattern.vercel.app/reviews`;

  const {
    data: { allReviews: allReviewsData = [], count: totalReviewCount = 0 } = {},
    isLoading: isLoadingAllReviews,
    refetch: refetchAllReviews,
  } = useQuery({
    queryKey: ["allReviewsData"],
    queryFn: async () => {
      const res = await fetch(allReviewsUrl);
      return res.json();
    },
  });

  const starCounts = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };

  // Ensure `allReviewsData` is an array before looping
  if (Array.isArray(allReviewsData)) {
    allReviewsData.forEach((review) => {
      if (review.star >= 1 && review.star <= 5) {
        starCounts[review.star]++;
      }
    });
  } else {
    // console.error("allReviewsData is not an array:", allReviewsData);
  }

  // Use `totalReviewCount` from API as the total number of reviews
  const totalReviews = totalReviewCount;

  // Fix `.reduce()` to correctly calculate total stars
  const totalStars = allReviewsData.reduce(
    (acc, review) => acc + review.star,
    0
  );

  const averageRating = totalReviews
    ? (totalStars / totalReviews).toFixed(1)
    : "0.0";

  const deleteHandler = (review) => {
    // console.log(setNewClass)
    const proceed = window.confirm(
      `Are you sure, you want to delete "${review?.name}" ?`
    );
    if (proceed) {
      fetch(`https://brooklyn-mvc-pattern.vercel.app/reviews/${review?._id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          //   console.log(data);
          if (data.deletedCount) {
            toast.success("Review Delete SuccessFully");
            refetch();
            refetchAllReviews();
          }
        });
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://brooklyn-mvc-pattern.vercel.app/reviews", {
        courseId: course?._id,
        courseName: `${course?.classTitle} - ${course?.startDate}`,
        ...formData,
      });
      toast.success("Review submitted successfully!");
      setFormData({ name: "", email: "", star: 5, comment: "" }); // Reset form
      setShowForm(false);
      refetch();
      refetchAllReviews();
    } catch (error) {
      //   console.error("Error submitting review:", error);
      alert("Failed to submit review.");
    }
  };

  return (
    <section className="mb-10 md:p-3 p-0">
      <h2 className="text-2xl font-bold mb-5 text-gray-900 dark:text-gray-100">
        Reviews
      </h2>

      <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-8">
        <div className="bg-gray-100 dark:bg-slate-400 p-6 rounded-lg flex flex-col items-center w-48 shadow-md">
          <span className="text-4xl font-bold text-red-500 dark:text-gray-100">
            {averageRating}
          </span>
          <div className="flex justify-center text-yellow-400 mt-2">
            {Array.from({ length: 5 }, (_, index) =>
              index < Math.round(averageRating) ? (
                <IoStarSharp key={index} />
              ) : (
                <FaRegStar key={index} />
              )
            )}
          </div>
          <span className="text-gray-500 text-sm mt-1 dark:text-gray-100">
            {totalReviews} ratings
          </span>
        </div>

        {/* Star Rating Breakdown */}
        <div className="flex-1 space-y-2">
          {[5, 4, 3, 2, 1].map((star) => (
            <div key={star} className="flex items-center space-x-3">
              <span className="text-gray-700 text-lg flex items-center gap-2 dark:text-gray-100">
                {star} <IoStarSharp className="text-yellow-400" />
              </span>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-yellow-400 h-2.5 rounded-full"
                  style={{
                    width: `${(starCounts[star] / totalReviews) * 100 || 0}%`,
                  }}
                ></div>
              </div>
              <span className="text-gray-700 text-sm dark:text-gray-100">
                {starCounts[star] || 0}
              </span>
            </div>
          ))}
        </div>
      </div>

      {/* "Give a Review" Button */}
      <button
        onClick={() => setShowForm(!showForm)}
        className="my-2 flex items-center gap-2 bg-[#0D9488] hover:bg-[#1d6862] text-white py-2 px-4 rounded-lg"
      >
        Rate This Course
        {showForm ? <IoChevronUp size={18} /> : <IoChevronDown size={18} />}
      </button>

      {/* Review Form */}
      {showForm && (
        <div className="mt-6 p-6 bg-gray-100 dark:bg-slate-400 rounded-lg shadow-md">
          <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">
            Submit Your Review
          </h3>
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Name Input */}
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full p-2 border rounded-lg dark:bg-slate-300 dark:text-gray-600 outline-none placeholder:text-gray-600"
            />

            {/* Email Input */}
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full p-2 border rounded-lg dark:bg-slate-300 dark:text-gray-600 outline-none placeholder:text-gray-600"
            />

            {/* Star Rating */}
            <div className="flex items-center space-x-2">
              <span className="text-gray-900 dark:text-gray-100">
                Your Rating:
              </span>
              <div className="flex">
                {[1, 2, 3, 4, 5].map((star) => (
                  <IoStarSharp
                    key={star}
                    className={`text-2xl cursor-pointer ${
                      star <= formData.star
                        ? "text-yellow-400"
                        : "text-gray-600"
                    }`}
                    onClick={() => handleStarClick(star)}
                  />
                ))}
              </div>
            </div>

            {/* Comment Input */}
            <textarea
              name="comment"
              placeholder="Write your review here..."
              value={formData.comment}
              onChange={handleChange}
              required
              className="w-full p-2 border rounded-lg dark:bg-slate-300 dark:text-gray-600 outline-none placeholder:text-gray-600"
            />

            {/* Submit Button */}
            <button
              type="submit"
              className="bg-[#0D9488] hover:bg-[#1d6862] text-white py-2 px-4 rounded-lg"
            >
              Submit Review
            </button>
          </form>
        </div>
      )}

      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          {allReviews?.length > 0 && (
            <AllReviews
              allReviews={allReviews}
              user={user}
              isAdmin={isAdmin}
              deleteHandler={deleteHandler}
              page={page}
              prevPage={prevPage}
              nextPage={nextPage}
              pages={pages}
              size={size}
              setSize={setSize}
            />
          )}
        </>
      )}
    </section>
  );
};

export default Reviews;
