import React from "react";
import { IoStarOutline, IoStarSharp } from "react-icons/io5";
import { MdArrowBackIosNew, MdOutlineNavigateNext } from "react-icons/md";

const AllReviews = ({
  allReviews = [],
  user,
  isAdmin,
  deleteHandler,
  page,
  pages,
  nextPage,
  prevPage,
  size,
  setSize,
}) => {
  //   console.log("All Reviews:", allReviews);

  if (!Array.isArray(allReviews)) {
    // console.error("Expected an array but got:", allReviews);
    return <p>No reviews available</p>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "long" });
    const year = date.getFullYear();

    // Add ordinal suffix in a simple way
    const suffix = ["st", "nd", "rd"][(day % 10) - 1] || "th";

    return `${day}${suffix} ${month} ${year}`;
  };

  return (
    <div>
      {allReviews.length > 0 ? (
        allReviews.map((review, index) => (
          <div
            key={index}
            className="  my-3 border rounded-md shadow-md px-2 py-1 md:px-4 md:py-2 flex flex-col md:flex-row justify-between"
          >
            <div className="flex items-center gap-2">
              <div>
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="md:w-[65px] md:h-[65px] w-[50px] h-[50px]"
                >
                  {/* Outer Circle with Padding */}
                  <circle
                    cx="12"
                    cy="12"
                    r="11"
                    strokeWidth="1"
                    fill="none"
                    className="stroke-[#0D9488] dark:stroke-white e"
                  />

                  {/* Head (Made Slightly Smaller) */}
                  <circle
                    cx="12"
                    cy="8"
                    r="4.5"
                    className="fill-[#0D9488] dark:fill-white dark:stroke-white"
                  />

                  {/* Shoulders (Reduced Slightly) */}
                  <path
                    d="M6 18C6 15 8.5 13 12 13C15.5 13 18 15 18 18"
                    className="fill-[#0D9488] dark:fill-white"
                  />
                </svg>
              </div>
              <div>
                <p className="font-bold  my-0.5 dark:text-gray-100 text-sm md:text-base">
                  {review?.courseName}
                </p>
                <div className="flex items-center gap-5 ">
                  <p className="text-gray-700 font-semibold dark:text-gray-100">
                    {review?.name}
                  </p>
                  <p className=" text-[#0D9488] text-xs italic font-semibold dark:text-gray-100">
                    {formatDate(review?.createdAt)}
                  </p>
                </div>
                <div className="flex items-center mt-1 ">
                  {[...Array(5)].map((_, i) =>
                    i < review?.star ? (
                      <IoStarSharp
                        key={i}
                        className="text-yellow-400 text-lg"
                      />
                    ) : (
                      <IoStarOutline
                        key={i}
                        className="text-gray-300 text-lg"
                      />
                    )
                  )}
                </div>
                {/* <p className="text-gray-500">{review?.email}</p> */}
                <p className="text-gray-500 my-0.5 font-medium dark:text-gray-100">
                  {review?.comment}
                </p>
              </div>
            </div>
            {user?.uid && (
              <>
                {isAdmin && (
                  <>
                    <button
                      className="bg-red-600 btn-sm text-white text-sm rounded-md my-3 md:my-0"
                      onClick={() => deleteHandler(review)}
                    >
                      Delete
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        ))
      ) : (
        <p>Loading reviews...</p>
      )}

      <div className="  pt-5  w-full ">
        <p className="text-center  text-sm font-semibold dark:text-gray-100 ml-3 ">
          Currently Selected page:{" "}
          <span className="text-primary mx-1 font-bold">{page + 1}</span> of{" "}
          <span className="text-[#14B8A6] mx-1 font-bold">{pages}</span>
        </p>
        <div>
          <div>
            <div className=" py-3 flex justify-center">
              <button
                className="flex items-center pr-2 pl-2 py-1 text-gray-500 ml-2 xl:ml-3 cursor-pointer border-[1px] border-gray-300
                         hover:bg-primary hover:text-white  dark:text-white text-sm shadow-sm"
                onClick={prevPage}
                disabled={page === 0}
              >
                <MdArrowBackIosNew className=" font-bold " />{" "}
                <MdArrowBackIosNew className=" -ml-1 " /> Prev
              </button>

              <button
                className="flex items-center  pl-2 py-1 text-gray-500 ml-2 xl:ml-3 cursor-pointer border-[1px] border-gray-300 hover:bg-primary hover:text-white shadow-sm dark:text-white text-sm"
                onClick={nextPage}
                disabled={page === pages - 1}
              >
                Next <MdOutlineNavigateNext className="text-xl" />{" "}
                <MdOutlineNavigateNext className="text-xl -ml-3" />
              </button>

              <select
                className="ml-2 xl:ml-3 dark:bg-gray-400 dark:text-gray-100 bg-white text-gray-500 border-[1px] border-gray-300 rounded-md focus:outline-none px-1 text-sm leading-tight font-medium shadow-sm"
                onChange={(event) => setSize(event.target.value)}
              >
                <option
                  selected
                  disabled
                  className="hidden"
                >{`Page Size ${size}`}</option>

                <option value="3">Page Size 3</option>
                <option value="10">Page Size 10</option>
                <option value="15">Page Size 15</option>
                <option value="20">Page Size 20</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllReviews;
