import { Link, useLoaderData } from "react-router-dom";
import shape from "../../../src/Assets/shape/shape-13.png";
import shape2 from "../../../src/Assets/shape/breadcrumb-shape-2.png";
import shape3 from "../../../src/Assets/shape/shape-07-1.png";
import { FaMoneyCheckAlt, FaRegClock, FaRegStar, FaStar } from "react-icons/fa";
import { FaArrowRightLong, FaUserDoctor } from "react-icons/fa6";
import { PiStudentBold } from "react-icons/pi";
import { GrLanguage } from "react-icons/gr";
import { useState } from "react";
import CourseContent from "./CourseContent";
import EnrollModal from "./EnrollModal";

// import EnrollModal from "./EnrollModal";

const ClassDetails = () => {
  const course = useLoaderData();

  // console.log(course);

  const [showModal, setShowModal] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Toggle modal visibility
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  // const youtubeUrl = `https://www.youtube.com/embed/${course?.youTubeUrl}?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1`;

  // console.log(youtubeUrl);

  return (
    <div className="min-h-screen  dark:bg-slate-600">
      {/* Banner Section */}
      <div className="relative bg-[#fce7eb] h-[300px] overflow-hidden">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Layer_1"
          version="1.1"
          viewBox="0 0 415 411"
          style={{ width: "400px", height: "400px" }}
          className="absolute -top-[220px] transform -translate-x-1/2 left-[150px] hidden lg:block "
        >
          <circle
            cx="207.69"
            cy="205.49"
            r="199.18"
            fill="none"
            strokeWidth="0.5"
            className="stroke-gray-400"
          />
        </svg>

        <img
          src={shape}
          alt="new-shape"
          className="spin-animation absolute top-[70px] left-[100px] hidden lg:block"
        />
        <img
          src={shape2}
          alt="new-shape"
          className=" absolute top-[130px] right-[170px] hidden lg:block float-animation"
        />
        <img
          src={shape3}
          alt="new-shape"
          className=" absolute -top-[40px] -right-[95px] hidden lg:block "
        />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Layer_1"
          version="1.1"
          viewBox="0 0 415 411"
          style={{ width: "480px", height: "480px" }}
          className=" absolute -bottom-[200px] -right-[200px] transform   hidden lg:block "
        >
          <circle
            cx="207.69"
            cy="205.49"
            r="199.18"
            fill="none"
            strokeWidth="0.5"
            className="stroke-gray-400"
          />
        </svg>

        <div className="container mx-auto px-4 h-full flex justify-center items-center ">
          <div className="text-center">
            {/* <Breadcrumbs /> */}

            <h1 className="text-2xl lg:text-5xl font-bold text-gray-800 mt-3">
              {course?.classType} {course?.classTitle}
            </h1>
            <div className="mt-5 flex items-center flex-col md:flex-row gap-3 text-gray-700">
              {/* Instructor */}
              <p className="flex items-center gap-2">
                <FaUserDoctor className="text-secondary" />
                <span className="font-semibold">By: {course?.instructor}</span>
              </p>

              {/* Divider */}
              <span className="text-gray-400 hidden md:block">|</span>

              {/* Language */}
              <p className="flex items-center gap-2">
                <GrLanguage className="text-secondary" />
                <span className="font-semibold">HHA</span>
              </p>

              {/* Divider */}
              <span className="text-gray-400 hidden md:block">|</span>

              {/* Rating */}
              <p className="flex items-center gap-2">
                {Array.from({ length: 5 }, (_, index) => (
                  <span key={index}>
                    {index < course?.rating ? (
                      <FaStar className="text-yellow-400" />
                    ) : (
                      <FaRegStar className="text-gray-400" />
                    )}
                  </span>
                ))}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Course Details Section */}
      <div className="container mx-auto lg:px-4 py-100  ">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Left Content */}
          <div className="lg:col-span-2">
            <CourseContent course={course} />
          </div>

          {/* Right Sidebar */}
          <div className="bg-white dark:bg-slate-400 p-6 rounded-lg shadow-lg order-first sm:order-none my-3 w-[95%] mx-auto md:w-full">
            <div className="relative w-full">
              {/* Image */}
              <img
                src={course?.img}
                alt={course?.classType}
                className="w-full rounded-lg mb-4"
              />

              {/* Play Button */}
              {/* <div className="absolute inset-0 flex items-center justify-center">
                <button
                  onClick={openModal}
                  className="w-14 h-14 bg-white hover:bg-secondary transition-colors duration-300 rounded-full flex items-center justify-center shadow-lg group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-pink-500 transition-colors duration-300 group-hover:text-white"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M8 5v14l11-7z" />
                  </svg>
                </button>
              </div> */}
            </div>
            <h3 className="text-xl font-bold mb-2 dark:text-gray-100">
              Course Includes:
            </h3>

            <ul className="list-none space-y-4 text-gray-600 dark:text-gray-100">
              {[
                {
                  label: "Price:",
                  value: `$${
                    parseInt(course?.registration || 0) +
                    parseInt(course?.tuition || 0) +
                    parseInt(course?.books || 0)
                  }`,
                  icon: <FaMoneyCheckAlt />,
                },
                {
                  label: "Instructor:",
                  value: course?.classTitle?.toLowerCase().includes("morning")
                    ? "Kausillia Nehall"
                    : "Sabine Durgaram",
                  icon: <FaUserDoctor />,
                },

                {
                  label: "Hours:",
                  value: "83",
                  icon: <FaRegClock />,
                },
                {
                  label: "Students:",
                  value: "30",
                  icon: <PiStudentBold />,
                },
                {
                  label: "Language:",
                  value: "English",
                  icon: <GrLanguage />,
                },
              ].map((item, index) => (
                <li
                  key={index}
                  className="flex items-center justify-between border-b pb-2"
                >
                  {/* Icon and Label Section */}
                  <div className="flex items-center space-x-2">
                    <span className="text-gray-500 dark:text-gray-100 text-lg">
                      {item.icon}
                    </span>
                    <strong className="text-gray-700 dark:text-gray-100">
                      {item.label}
                    </strong>
                  </div>

                  {/* Value Section */}
                  <span
                    className={`${
                      item.label === "Price:"
                        ? "text-primary font-bold"
                        : "text-right font-medium"
                    }`}
                  >
                    {item.value}
                  </span>
                </li>
              ))}
            </ul>

            <h3 className="text-xl font-bold mt-6 mb-2 dark:text-gray-100">
              Fees Breakdown:
            </h3>
            <ul className="list-none space-y-2 text-gray-600 dark:text-gray-100">
              <li>
                <strong>Registration:</strong> ${course?.registration}
              </li>
              <li>
                <strong>Tuition:</strong> ${course?.tuition}
              </li>
              <li>
                <strong>Textbook:</strong> ${course?.books}
              </li>
            </ul>

            <div className="mt-6">
              {course?.classType === "Upcoming" ? (
                <button
                  onClick={toggleModal}
                  className="w-full bg-teal-500 text-white px-4 py-2 rounded-lg font-bold hover:bg-teal-600 flex items-center gap-2 justify-center"
                >
                  Send an Enroll Request <FaArrowRightLong />
                </button>
              ) : (
                <>
                  <Link
                    to="/classes"
                    className="w-full bg-teal-500 text-white px-4 py-2 rounded-lg font-bold hover:bg-teal-600 flex items-center gap-2 justify-center"
                  >
                    Sell All Classes
                    <FaArrowRightLong />
                  </Link>
                </>
              )}

              {isModalOpen && (
                <EnrollModal course={course} onClose={toggleModal} />
              )}
            </div>
          </div>
        </div>

        {/* {showModal ? (
          <ModalBody youtubeUrl={youtubeUrl} closeModal={closeModal} />
        ) : null} */}
      </div>
    </div>
  );
};

export default ClassDetails;
