import React, { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import Loading from "../../../Shared/Loading";
import { FaUsers } from "react-icons/fa";
import { TiMessages } from "react-icons/ti";
import { RiMailSendLine } from "react-icons/ri";
import Rechart from "./Rechart";
import RoundChart from "./RoundChart";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthProvider";
import AreaRechartComponent from "./AreaChartComponent";
import { useLocation } from "react-router-dom";

const GraphPage = () => {
  const { visitors } = useContext(AuthContext);

  const previousVisitors = 225;

  let presentVisitors = 0;

  const location = useLocation();
  const isDashboard = location.pathname.toLowerCase() === "/dashboard";

  if (visitors) {
    for (const visitor of visitors) {
      presentVisitors = presentVisitors + visitor?.visitor;
    }
  }

  const url = `https://brooklyn-mvc-pattern.vercel.app/notifications`;

  const {
    data: allData = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["notifications"],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });

  let totalMessage = 0;

  // console.log(allData?.messages)

  if (allData?.length <= 0) {
    return <Loading></Loading>;
  } else {
    for (const message of allData?.users) {
      if (message?.chat) {
        totalMessage = totalMessage + 1;
      }
    }
  }

  // console.log(totalMessage);

  // console.log(visitors)

  return (
    <div className="border-2 min-h-screen">
      {/* conditional component */}

      {isDashboard && (
        <div className="md:flex">
          <div className="w-[100%] mx-auto lg:ml-[4%]">
            <div className="w-[95%] mx-auto grid md:grid-cols-2 lg:grid-cols-4 mt-10 gap-7 items-center ">
              <Link to="/dashboard/allUsers">
                <div className="  bg-gradient-to-r from-sky-500 to-indigo-500 text-primary-content rounded-xl py-4 shadow-md">
                  <div className="">
                    <p className="flex justify-center  text-4xl text-gray-100 ">
                      <FaUsers></FaUsers>
                    </p>
                    <p className="flex justify-center font-semibold text-gray-100 gap-2 items-center">
                      Total Users:
                      <span className="text-xl"> {allData?.users?.length}</span>
                    </p>
                  </div>
                </div>
              </Link>
              <Link to="/dashboard/allMessages">
                <div className="rounded-xl bg-gradient-to-r from-sky-500 to-indigo-500 text-primary-content py-4 shadow-md">
                  <div className="">
                    <p className="flex justify-center  text-4xl text-gray-100 ">
                      <TiMessages></TiMessages>
                    </p>
                    <p className="flex justify-center font-semibold text-gray-100 gap-2 items-center">
                      General Message:{" "}
                      <span className="text-xl"> {totalMessage}</span>
                    </p>
                  </div>
                </div>
              </Link>

              <Link to="/dashboard/allMails">
                <div className=" rounded-xl bg-gradient-to-r from-sky-500 to-indigo-500 text-primary-content py-4 shadow-md">
                  <div className="">
                    <p className="flex justify-center  text-4xl text-gray-100 ">
                      <RiMailSendLine></RiMailSendLine>
                    </p>
                    <p className="flex justify-center font-semibold text-gray-100 gap-2 items-center">
                      Mailing List:{" "}
                      <span className="text-xl"> {allData?.mails?.length}</span>
                    </p>
                  </div>
                </div>
              </Link>

              <div>
                <div className="flex items-center gap-3">
                  <p className="bg-gradient-to-r from-sky-500 to-indigo-500 text-white w-14 h-14 rounded-full flex items-center justify-center shadow-lg">
                    <FaUsers className="text-3xl"></FaUsers>{" "}
                  </p>

                  <div>
                    <p className="text-gray-500 font-medium text-sm dark:text-gray-200">
                      Total Visitors
                    </p>
                    <p className="flex justify-center  font-semibold text-gray-600 dark:text-gray-200">
                      {presentVisitors + previousVisitors}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* conditional component End */}

      <div className="mt-12 lg:ml-[2%] lg:flex justify-evenly">
        <div className="lg:w-[50%] w-full ">
          <Rechart></Rechart>
        </div>
        <div className="md:flex ml-[3%] items-center lg:w-[50%] w-full">
          <div className="flex justify-center md:block mt-5 md:mt-0">
            <RoundChart
              allData={allData}
              totalMessage={totalMessage}
            ></RoundChart>
          </div>
          <div className="flex justify-center">
            <div>
              <div className="flex gap-2 items-center my-2">
                <p className="flex bg-[#00C49F] px-2 py-2"></p>
                <p className="font-medium text-sm text-gray-600 dark:text-gray-200">
                  - Users
                </p>
              </div>
              <div className="flex gap-2 items-center my-2">
                <p className="flex bg-[#FFBB28] px-2 py-2"></p>
                <p className="font-medium text-sm text-gray-600 dark:text-gray-200">
                  - General Message
                </p>
              </div>
              <div className="flex gap-2 items-center my-2">
                <p className="flex bg-[#0088FE] px-2 py-2"></p>
                <p className="font-medium text-sm text-gray-600 dark:text-gray-200">
                  - Mailing List
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-[95%] mx-auto mt-16 pb-16">
        <AreaRechartComponent></AreaRechartComponent>
      </div>
    </div>
  );
};

export default GraphPage;
